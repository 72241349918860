import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import upd from 'immutability-helper';

import {Button, Confirm, TableData, TableRow} from 'components';
import {useAppContext} from 'app-context';
import {useRequest, useTariffPeriod} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Subscription {
  setSubscriptionList: AppEnv.SetState<AppEnv.Subscription[] | undefined>;
  subscription: AppEnv.Subscription;
}

const Subscription = ({setSubscriptionList, subscription}: Subscription) => {
  const {setNotification} = useAppContext();
  const {user} = useWorkspaceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);

  const {fetchSubscriptionsCancel} = useRequest();
  const {t} = useTranslation();
  const period = useTariffPeriod(subscription.tariff_quantity);

  const cancelSubscription = async () => {
    const {integration} = user;

    const res = await fetchSubscriptionsCancel(
      integration.id,
      subscription.subscription_id,
      subscription.title
    );

    if (!res) return true;

    setIsConfirmActive(false);
    setNotification({title: t`Subscription has been canceled`});

    setSubscriptionList(prevValue => {
      const index = prevValue?.findIndex(
        item => item.subscription_id == subscription.subscription_id
      );

      if (index == undefined || index < 0) return prevValue;
      return upd(prevValue, {$splice: [[index, 1]]});
    });

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return cancelSubscription();
    setIsConfirmActive(false);
  };

  const {integration, manager} = user;
  const {billing} = manager.roles;

  return (
    <TableRow isActive={isConfirmActive}>
      <TableData>
        {moment(subscription.date_add).utcOffset(3).format('L LT')}
      </TableData>

      <TableData>{subscription.tariff_plane || 'Start'}</TableData>
      <TableData>{period}</TableData>

      <TableData>
        {subscription.title == 'cloudpayments'
          ? 'CloudPayments'
          : subscription.title == 'stripe'
            ? 'Stripe'
            : subscription.title}
      </TableData>

      <TableData>{subscription.quantity}</TableData>

      {integration.is_partner > 0 && (
        <TableData>{subscription.domain}</TableData>
      )}

      {billing.write && (
        <TableData isBtn>
          <Button color="transparent" onClick={() => setIsConfirmActive(true)}>
            <span>{t`Cancel`}</span>
          </Button>

          <Confirm
            isActive={isConfirmActive}
            onClick={handleClick}
            title={t`Cancel this subscription?`}
          />
        </TableData>
      )}
    </TableRow>
  );
};

export default Subscription;
