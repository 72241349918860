import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Tariffs} from 'pages/workspace/components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const Plans = () => {
  const {user} = useWorkspaceContext();
  const [tariffs, setTariffs] = useState<AppEnv.Tariffs | null>();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Rate plans`} - Whatcrm Web`;
  }, []);

  const {manager} = user;
  const {instances} = manager.roles;

  return (
    <Tariffs
      instances={user.instances}
      integration={user.integration}
      isWrite={instances.write}
      setTariffs={setTariffs}
      tariffs={tariffs}
    />
  );
};

export default Plans;
