import React from 'react';

import {Text} from 'components';
import {useTariffPeriod} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Quantity {
  tariff: AppEnv.Tariff;
}

const Quantity = ({tariff}: Quantity) => {
  const period = useTariffPeriod(tariff.quantity);

  return (
    <Text fontWeight={500} style={{marginBottom: 8}}>
      {period}
    </Text>
  );
};

export default Quantity;
