import React from 'react';
import {useTranslation} from 'react-i18next';

import {SingleSelect} from 'components';

const Lang = () => {
  const {i18n} = useTranslation();

  const handleChange = (value: string) => {
    i18n.changeLanguage(value);
    window.location.reload();
  };

  const options = [
    {comment: 'Deutsche', label: 'DE', value: 'de'},
    {comment: 'English', label: 'EN', value: 'en'},
    {comment: 'Español', label: 'ES', value: 'es'},
    {comment: 'Português', label: 'PT', value: 'pt'},
    {comment: 'Русский', label: 'RU', value: 'ru'},
    {comment: 'Türkçe', label: 'TR', value: 'tr'},
    {comment: 'Українська', label: 'UK', value: 'uk'}
  ];

  return (
    <SingleSelect
      options={options}
      value={(i18n.resolvedLanguage || 'en').slice(0, 2)}
      isAlt
      onChange={handleChange}
    />
  );
};

export default Lang;
