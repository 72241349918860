import React, {useEffect} from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {
  PageBody,
  PageFooter,
  PageMain,
  Placeholder,
  SaveButton,
  Title
} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import IsManager from './is-manager/is-manager';

interface Values {
  is_manager: 0 | 1;
}

const Integration = () => {
  const {setNotification} = useAppContext();
  const {setUser, user} = useWorkspaceContext();

  const {t} = useTranslation();
  const {postIntegrationsUpdate} = useRequest();

  useEffect(() => {
    document.title = `${t`Integration settings`} - Whatcrm Web`;
  }, []);

  const pushNotification = () => {
    setNotification({title: t`The settings have been saved`});
    return true;
  };

  const handleSubmit = async (values: Values) => {
    const {integration} = user;

    if (values.is_manager == integration.is_manager) return pushNotification();

    const res = await postIntegrationsUpdate(integration.id, {
      is_manager: values.is_manager
    });

    if (!res) return true;

    setUser(prevValue =>
      update(prevValue, {integration: {is_manager: {$set: values.is_manager}}})
    );

    return pushNotification();
  };

  const {manager} = user;
  const {settings} = manager.roles;

  const initialValues: Values = {is_manager: user.integration.is_manager};

  return (
    <>
      {settings.read ? (
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <PageBody>
              <PageMain>
                <Title.H3 style={{marginBottom: 12}}>{t`Chat`}</Title.H3>
                <IsManager />
              </PageMain>
            </PageBody>

            <PageFooter>
              <SaveButton>{t`Save`}</SaveButton>
            </PageFooter>
          </Form>
        </Formik>
      ) : (
        <PageBody>
          <PageMain>
            <Placeholder isMaxHeight title={t`Access denied`} />
          </PageMain>
        </PageBody>
      )}
    </>
  );
};

export default Integration;
