import {ItemParams} from '../../../components';
import * as AppEnv from 'app-env';

import useBulletin from './use-bulletin/use-bulletin';
import useLocation from './use-location/use-location';
import usePhoneNumber from './use-phone-number/use-phone-number';
import useUsername from './use-username/use-username';
import useBlock from './use-block/use-block';

const useItems = (
  setIsConfirmActive: AppEnv.SetState<boolean>
): ItemParams[] => {
  const block = useBlock(setIsConfirmActive);
  const bulletin = useBulletin();
  const location = useLocation();
  const phoneNumber = usePhoneNumber();
  const username = useUsername();

  const items = [bulletin, location, phoneNumber, username, block].filter(
    item => item != null
  );

  return items as ItemParams[];
};

export default useItems;
