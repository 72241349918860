import {
  getIsLimited,
  getIsMessageOutgoing,
  getMessageTimestamp
} from 'common/actions';
import * as AppEnv from 'app-env';

const getElapsedTimeLimit = (message: AppEnv.Message) => {
  if ('sender_id' in message) return 86400;
  else if ('senderId' in message) return 172800;

  return 900;
};

const getIsEditAvailable = (
  instance: AppEnv.Instance,
  dialog: AppEnv.Dialog,
  message: AppEnv.Message
) => {
  if (!['chat', 'telegram', 'whatcrm'].includes(dialog.version)) return false;

  const isLimited = getIsLimited(instance);
  if (isLimited) return false;

  const elapsedTimeLimit = getElapsedTimeLimit(message);
  const timestamp = getMessageTimestamp(message) || 0;
  const isDueTime = +new Date() / 1000 - timestamp < elapsedTimeLimit;
  const isMessageOutgoing = getIsMessageOutgoing(dialog, message);

  const isTextMessage =
    message.type == 'chat' ||
    message.type == 'message' ||
    message.type == 'text';

  return isDueTime && isMessageOutgoing && isTextMessage;
};

export default getIsEditAvailable;
