import React from 'react';

import {Icons, Title, Wrapper} from 'components';
import {useGetInstanceLabel} from 'common/hooks';
import {useInstanceContext} from '../../instance-context';

interface Label {
  style?: React.CSSProperties;
}

const iconStyle = {marginLeft: -7};

const icons = {
  avito: <Icons.Avito style={iconStyle} />,
  chat: <Icons.CallCenterOperator style={iconStyle} />,
  telegram: <Icons.Telegram style={iconStyle} />,
  whatcrm: <Icons.WhatsApp style={iconStyle} />
};

const Label = ({style}: Label) => {
  const {instance} = useInstanceContext();
  const getInstanceLabel = useGetInstanceLabel();

  const label = getInstanceLabel(instance);

  const titleStyle: React.CSSProperties = {
    direction: !instance.label ? 'rtl' : undefined,
    fontWeight: 400,
    marginTop: 4
  };

  return (
    <Wrapper flexWrap="nowrap" style={{...style}}>
      {icons[instance.version]}

      <Title.H3 style={titleStyle} isNoWrap>
        {label}
      </Title.H3>
    </Wrapper>
  );
};

export default Label;
