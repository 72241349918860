import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface AccessContext {
  setManagerList: AppEnv.SetState<AppEnv.Manager[] | undefined>;
}

const AccessContext = React.createContext<AccessContext>({} as AccessContext);

export const useAccessContext = () => useContext(AccessContext);
export default AccessContext;
