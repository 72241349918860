import React from 'react';
import {useTranslation} from 'react-i18next';

import {Grid, Placeholder} from 'components';
import * as AppEnv from 'app-env';

import Instance from './instance/instance';

interface InstanceList {
  instances: AppEnv.Instances;
  isWrite: boolean;
}

const InstanceList = ({instances, isWrite}: InstanceList) => {
  const {t} = useTranslation();
  const instanceList = Object.values(instances);

  return (
    <>
      {instanceList.length ? (
        <Grid>
          {instanceList.map(instance => (
            <Instance instance={instance} isWrite={isWrite} key={instance.id} />
          ))}
        </Grid>
      ) : (
        <Placeholder isMaxHeight title={t`No connections`} />
      )}
    </>
  );
};

export default InstanceList;
