import React, {useEffect, useState} from 'react';
import {formatPhoneNumber, useOnKeyDown} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import Fuse from 'fuse.js';
import moment from 'moment';

import {
  Button,
  Icons,
  Input,
  Placeholder,
  Popup,
  PopupFooter,
  PopupWrapper,
  RadioAlt,
  Text,
  Title,
  Wrapper
} from 'components';
import {useChatContext} from '../../../../chat-context';
import {useFooterContext} from '../../../footer-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {getDialogName, getDialogPhoneNumber} from 'common/actions';
import * as AppEnv from 'app-env';

const Template = () => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog, telegramUser} = useChatContext();
  const {setTemplateState, templateState} = useFooterContext();

  const [search, setSearch] = useState('');
  const [templateId, setTemplateId] = useState<number>();
  const [templateList, setTemplateList] = useState(user.templates);

  const [, , attachsHelpers] = useField<AppEnv.Attach[]>('attachs');
  const [, , textHelpers] = useField<string>('text');
  const {t} = useTranslation();

  const formatMessage = (message: string) => {
    const phoneNumber = getDialogPhoneNumber(dialog, telegramUser);

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    return message
      .replaceAll('%CLIENT_NAME%', getDialogName(instance, dialog) || '')
      .replaceAll(
        '%CLIENT_NUMBER%',
        phoneNumber ? formatPhoneNumber(phoneNumber) : ''
      )
      .replaceAll('%COMPANY_NAME%', instance.name)
      .replaceAll('%COMPANY_NUMBER%', formatPhoneNumber(instance.phone))
      .replaceAll('%TODAY%', moment(today).format('L'))
      .replaceAll('%TOMORROW%', moment(tomorrow).format('L'));
  };

  const selectTemplate = () => {
    const template = user.templates.find(item => item.id == templateId);
    if (!template) return;

    if (template.files) {
      attachsHelpers.setValue(
        template.files.map(item => ({
          isValid: true,
          src: item.link,
          title: item.title,
          type: item.type || ''
        }))
      );
    }

    textHelpers.setValue(formatMessage(template.message));
    setTemplateState({isActive: false});
  };

  useOnKeyDown(
    e => {
      if (e.code == 'Enter' || e.code == 'NumpadEnter') selectTemplate();
    },
    [templateId, templateState.isActive]
  );

  useEffect(() => {
    if (!search) return setTemplateList(user.templates);

    const fuse = new Fuse(user.templates, {
      keys: ['message', 'title'],
      threshold: 0.25
    });

    const fusedTemplateList = fuse.search(search).map(item => item.item);
    setTemplateList(fusedTemplateList);
  }, [search]);

  useEffect(() => {
    if (!templateState.isActive) setTemplateId(undefined);
  }, [templateState.isActive]);

  const handleClick = () => {
    setTemplateState({isActive: false});
    if (templateState.isFocus) textHelpers.setValue('/');
  };

  const options = templateList.map(item => ({
    label: (
      <Wrapper flexWrap="nowrap" justifyContent="space-between">
        <Wrapper
          alignItems="center"
          flexWrap="nowrap"
          gap={8}
          style={{overflow: 'hidden'}}
        >
          <Icons.Note />

          <div style={{overflow: 'hidden'}}>
            <Text size={16} fontWeight={600}>
              {item.title}
            </Text>

            <Text color="light" fontWeight={600} isNoWrap size={14}>
              {formatMessage(item.message)}
            </Text>
          </div>
        </Wrapper>

        {(item.file_count || 0) > 0 && (
          <Wrapper alignItems="center" alignSelf="flex-start" flexWrap="nowrap">
            <Icons.PaperClip color="green" size={16} />
            <Text color="green" size={14}>
              {item.file_count}
            </Text>
          </Wrapper>
        )}
      </Wrapper>
    ),
    value: item.id
  }));

  return (
    <Popup width={408} isActive={templateState.isActive} onClick={handleClick}>
      <PopupWrapper>
        <Title.H3 style={{marginBottom: 16}}>{t`Select a template`}</Title.H3>

        {user.templates.length ? (
          <Wrapper direction="column" gap={16}>
            <Input
              autoCapitalize="off"
              autoFocus
              color="gray"
              isAlt
              name="new-search"
              onChange={setSearch}
              placeholder={t`Search`}
              type="search"
              value={search}
            />

            {options.length > 0 && (
              <RadioAlt
                options={options}
                value={templateId}
                isFocus={templateState.isFocus}
                onChange={setTemplateId}
                onSubmit={selectTemplate}
              />
            )}
          </Wrapper>
        ) : (
          <Placeholder title={null} />
        )}
      </PopupWrapper>

      <PopupFooter>
        <Wrapper gap={12}>
          <Button
            onClick={selectTemplate}
            state={typeof templateId == 'undefined' ? 'disabled' : undefined}
          >
            {t`Select`}
          </Button>

          <Button color="fade" onClick={handleClick}>
            {t`Close`}
          </Button>
        </Wrapper>
      </PopupFooter>
    </Popup>
  );
};

export default Template;
