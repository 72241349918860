import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useMessageContext} from '../../../../../../message-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const useReply = (): ContextMenuOption | null => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {setQuotedMsg} = useChatContext();
  const {message} = useMessageContext();

  const {t} = useTranslation();

  const {manager} = user;
  const {messages} = manager.roles;

  if (!['telegram', 'whatcrm', 'chat'].includes(instance.version)) return null;
  else if (!messages.reply) return null;

  const reply: ContextMenuOption = {
    icon: <IconsAlt.Reply />,
    label: t`Reply`,
    onClick: () => setQuotedMsg({message})
  };

  return reply;
};

export default useReply;
