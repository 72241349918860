import React from 'react';
import cn from 'classnames';

interface TableData {
  children?: React.ReactNode;
  colSpan?: number;
  isBackground?: boolean;
  isBtn?: boolean;
  isIcon?: boolean;
}

const TableData = ({
  children,
  colSpan,
  isBackground,
  isBtn,
  isIcon
}: TableData) => (
  <td
    className={cn('table__data', {
      table__data_background: isBackground,
      table__data_btn: isBtn || isIcon,
      table__data_icon: isIcon
    })}
    colSpan={colSpan}
  >
    {children}
  </td>
);

export default TableData;
