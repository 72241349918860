import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {SelectOption, SingleSelect} from 'components';
import {useManagerContext} from '../manager-context';
import {useRequest} from 'common/hooks';
import {useUpdManagerRoles} from '../hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

type Value = '' | 'write';

interface Messages {
  getLabel: (str: string) => string;
}

const Settings = ({getLabel}: Messages) => {
  const {user} = useWorkspaceContext();
  const {manager} = useManagerContext();

  const {postManagersUpdate} = useRequest();
  const {t} = useTranslation();
  const updManagerRoles = useUpdManagerRoles();

  const handleChange = async (value: Value) => {
    const {integration} = user;

    const newValue: AppEnv.ManagerRolesDefault = {
      read: value != '',
      write: value != ''
    };

    const newRoles = upd(manager.roles, {settings: {$set: newValue}});
    updManagerRoles(newRoles);

    const res = await postManagersUpdate(integration.id, manager.id, {
      roles: newRoles
    });

    if (res) return;
    updManagerRoles(manager.roles);
  };

  const {settings} = manager.roles;

  const options: SelectOption<Value>[] = [
    {label: t`No access`, value: ''},
    {label: t`Full access`, value: 'write'}
  ];

  const value: Value = settings.write ? 'write' : '';

  return (
    <SingleSelect
      isAlt
      maxWidth={160}
      onChange={handleChange}
      optgroup={getLabel(t`Settings`)}
      options={options}
      value={value}
    />
  );
};

export default Settings;
