import React, {useState} from 'react';
import {useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {
  Button,
  Confirm,
  Icons,
  Link,
  PageContainer,
  Prop,
  PropList,
  Text,
  Wrapper
} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {useAuth, useStatus, useToken} from './hooks';

interface Integrator {
  integrator: AppEnv.Integrator;
  setIntegrator: AppEnv.SetState<AppEnv.Integrator | null | undefined>;
}

const Integrator = ({integrator, setIntegrator}: Integrator) => {
  const {setNotification} = useAppContext();
  const {user} = useWorkspaceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);

  const {fetchIntegratorsGeneration} = useRequest();
  const {t} = useTranslation();
  const auth = useAuth(integrator);
  const copyToClipboard = useCopyToClipboard();
  const status = useStatus(integrator);
  const token = useToken(integrator);

  const updateToken = async () => {
    const {integration} = user;

    const res = await fetchIntegratorsGeneration(integration.id);
    if (!res) return true;

    setIntegrator(prevValue =>
      upd(prevValue, {token: {token: {$set: res.token}}})
    );

    setIsConfirmActive(false);
    setNotification({title: t`Header token has been updated`});

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return updateToken();
    setIsConfirmActive(false);
  };

  const handleCopy = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  const {integration, manager} = user;
  const {integrators} = manager.roles;

  const doc = 'https://documenter.getpostman.com/view/16489057/TzzDLFp4';

  return (
    <>
      <PageContainer>
        <PropList>
          <Prop>
            <Text size={12}>{t`widget code`}</Text>

            <Text fontWeight={600} size={16}>
              {integration.widget_code}
            </Text>
          </Prop>

          <Prop>
            <Text size={12}>{t`status`}</Text>

            <Text fontWeight={600} size={16}>
              {status}
            </Text>
          </Prop>

          <Prop>
            <Wrapper
              alignItems="flex-end"
              flexWrap="nowrap"
              gap={16}
              justifyContent="space-between"
            >
              <div style={{minWidth: 0}}>
                <Text size={12}>{t`registration link for new clients`}</Text>

                <Text fontWeight={600} isNoWrap size={16}>
                  {auth}
                </Text>
              </div>

              <Button
                color="transparent"
                onClick={() => handleCopy(auth)}
                style={{marginBottom: -4}}
              >
                <Icons.Files size={20} />
              </Button>
            </Wrapper>
          </Prop>

          <Prop>
            <Wrapper
              alignItems="flex-end"
              flexWrap="nowrap"
              gap={16}
              justifyContent="space-between"
            >
              <div style={{minWidth: 0}}>
                <Text size={12}>{t`header token`}</Text>

                <Text
                  fontWeight={600}
                  isNoWrap
                  size={16}
                  style={{fontFamily: 'monospace'}}
                >
                  {token}
                </Text>
              </div>

              <Wrapper flexWrap="nowrap" style={{marginBottom: -4}}>
                {integrators.write && (
                  <Button
                    color="transparent"
                    onClick={() => setIsConfirmActive(true)}
                  >
                    <Icons.ArrowClockwise size={20} />
                  </Button>
                )}

                <Button color="transparent" onClick={() => handleCopy(token)}>
                  <Icons.Files size={20} />
                </Button>
              </Wrapper>
            </Wrapper>
          </Prop>

          <Prop>
            <Text size={12}>{t`API documentation`}</Text>

            <Text fontWeight={600} isNoWrap size={16}>
              <Link href={doc} isTargetBlank>
                {doc}
              </Link>
            </Text>
          </Prop>
        </PropList>
      </PageContainer>

      <Confirm
        isActive={isConfirmActive}
        title={t`Update header token?`}
        onClick={handleClick}
      />
    </>
  );
};

export default Integrator;
