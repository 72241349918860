import {getIsLimited, getIsNewMessageAvailable} from 'common/actions';
import * as AppEnv from 'app-env';

const getIsDeleteAvailable = (
  dialog: AppEnv.Dialog,
  instance: AppEnv.Instance,
  message: AppEnv.Message
) => {
  const isNewMessageAvailable = getIsNewMessageAvailable(instance, dialog);
  if (!isNewMessageAvailable) return false;

  const isLimited = getIsLimited(instance);
  if (isLimited) return false;

  const elapsedTimeLimit = 3600;
  if (!('created' in message)) return true;

  const isDueTime = +new Date() / 1000 - message.created < elapsedTimeLimit;
  return isDueTime;
};

export default getIsDeleteAvailable;
