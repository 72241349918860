import React from 'react';
import {useTranslation} from 'react-i18next';

import {TableData, TableRow} from 'components';
import * as AppEnv from 'app-env';

interface Bonus {
  bonus: AppEnv.TelphinBonus;
}

const Bonus = ({bonus}: Bonus) => {
  const {i18n} = useTranslation();

  return (
    <TableRow>
      <TableData>{bonus.bonus_name}</TableData>

      <TableData>
        {(+bonus.current_price).toLocaleString(i18n.resolvedLanguage, {
          currency: 'RUB',
          style: 'currency'
        })}
      </TableData>

      <TableData>{bonus.work_status}</TableData>
    </TableRow>
  );
};

export default Bonus;
