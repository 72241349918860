import React from 'react';
import cn from 'classnames';

import {useScrollClassName} from 'common/hooks';

interface Code {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Code = ({children, style}: Code) => {
  const scrollClassName = useScrollClassName();

  return (
    <p
      className={cn('code', {[scrollClassName]: scrollClassName})}
      style={style}
    >
      {children}
    </p>
  );
};
export default Code;
