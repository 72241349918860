import React, {useEffect, useState} from 'react';
import {useAudioRecorder} from 'react-audio-voice-recorder';
import {useField} from 'formik';
import {useLongPress} from 'use-long-press';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {getTime} from 'common/actions';
import {Icons, Text, Tooltip, Wrapper} from 'components';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import getWaveform from './get-waveform';

interface Recorder {
  isAlt: boolean | undefined;
  isDisabled: boolean | undefined;
  isVoiceRecording: boolean;
  setIsVoiceRecording: AppEnv.SetState<boolean>;
  version: AppEnv.InstanceVersion;
}

const Recorder = ({
  isAlt,
  isDisabled,
  isVoiceRecording,
  setIsVoiceRecording,
  version
}: Recorder) => {
  const {getFfmpeg, isCanPlayOgg, setNotification} = useAppContext();
  const [duration, setDuration] = useState(0);
  const [, , helpers] = useField<AppEnv.Voice | undefined>('voice');

  const handleNotAllowedOrFound = () =>
    setNotification({
      text: t`web.whatcrm.net does not have permission to use your microphone.`,
      title: t`Error`
    });

  const {
    isRecording,
    recordingBlob,
    recordingTime,
    startRecording,
    stopRecording
  } = useAudioRecorder(undefined, handleNotAllowedOrFound);

  const {t} = useTranslation();

  useEffect(() => {
    if (isRecording) setIsVoiceRecording(true);
  }, [isRecording]);

  useEffect(() => {
    if (recordingBlob) handleRecordingBlob(recordingBlob);
  }, [recordingBlob]);

  useEffect(() => {
    if (recordingTime) setDuration(recordingTime);
  }, [recordingTime]);

  const handleRecordingBlob = async (res: Blob) => {
    const type = isCanPlayOgg ? 'audio/ogg; codecs=opus' : res.type;
    const blob = new Blob([res], {type});

    const arrayBuffer = await blob.arrayBuffer();
    const waveform = getWaveform(arrayBuffer);

    helpers.setValue({blob, duration, waveform});
    setDuration(0);
  };

  const handleLongPress = () => {
    startRecording();
    if (!isCanPlayOgg || version == 'whatcrm') getFfmpeg();
  };

  const bindLongPress = useLongPress(handleLongPress, {
    cancelOutsideElement: false,
    onCancel: stopRecording,
    onFinish: stopRecording
  });

  const time = getTime(recordingTime);

  return (
    <Wrapper
      alignItems="center"
      flexWrap="nowrap"
      gap={16}
      justifyContent="space-between"
      style={isVoiceRecording ? {width: '100%'} : undefined}
    >
      {isVoiceRecording ? (
        <Wrapper flexWrap="nowrap" gap={16}>
          <Icons.CircleFill color="red" size={16} />
          <Text color="secondary" size={14}>
            {time}
          </Text>
        </Wrapper>
      ) : (
        isAlt && <div />
      )}

      <Wrapper alignItems="center" flexWrap="nowrap" gap={16}>
        {isVoiceRecording ? (
          <Text color="green" size={16} textAlign={isAlt ? 'right' : undefined}>
            {t`Release the button to stop recording`}
          </Text>
        ) : (
          isAlt && (
            <Text color="green" size={16} textAlign="right">
              {t`Hold the button to start recording`}
            </Text>
          )
        )}

        <button
          {...bindLongPress()}
          className={cn('voice__btn', {voice__btn_active: isVoiceRecording})}
          disabled={isDisabled}
          type="button"
        >
          <Icons.Microphone />

          {!isAlt && (
            <Tooltip>
              <Text color="white" size={14}>{t`Hold to record`}</Text>
            </Tooltip>
          )}
        </button>
      </Wrapper>
    </Wrapper>
  );
};

export default Recorder;
