import React from 'react';
import {useTranslation} from 'react-i18next';

import {Body, Page, PageBody, PageMain, Placeholder} from 'components';
import {useWorkspaceContext} from '../workspace-context';
import * as AppEnv from 'app-env';

interface Firewall {
  children: React.ReactNode;
  roleKey: AppEnv.RoleKey;
}

const Firewall = ({children, roleKey}: Firewall) => {
  const {user} = useWorkspaceContext();
  const {t} = useTranslation();

  const {integration, manager} = user;
  const {roles} = manager;

  const isIntegrators =
    roleKey != 'integrators' || integration.is_integration > 0;

  const isPartners = roleKey != 'partners' || integration.is_partner > 0;

  const role = roles[roleKey];
  const isAccess = isIntegrators && isPartners && role.read;

  return (
    <>
      {isAccess ? (
        children
      ) : (
        <Body>
          <Page>
            <PageBody>
              <PageMain>
                <Placeholder isMaxHeight title={t`Access denied`} />
              </PageMain>
            </PageBody>
          </Page>
        </Body>
      )}
    </>
  );
};

export default Firewall;
