import React, {useEffect} from 'react';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import {
  CoreEnv,
  getFromLocalStorage,
  putInLocalStorage,
  removeFromLocalStorage
} from 'whatcrm-core';

import {
  PageBody,
  PageFooter,
  PageMain,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {useAppContext} from 'app-context';

import IsTextTone from './is-text-tone/is-text-tone';
import Theme from './theme/theme';

interface Values {
  isTextTone: boolean;
  theme: CoreEnv.Theme;
}

const Personal = () => {
  const {setNotification, setTheme, theme} = useAppContext();

  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Personal settings`} - Whatcrm Web`;
  }, []);

  const pushNotification = () => {
    setNotification({title: t`The settings have been saved`});
    return true;
  };

  const updateTextTone = (isTextTone: boolean) => {
    if (isTextTone) return putInLocalStorage('textTone', '1');
    removeFromLocalStorage('textTone');
  };

  const handleSubmit = async (values: Values) => {
    setTheme(values.theme);
    updateTextTone(values.isTextTone);

    return pushNotification();
  };

  const initialValues: Values = {
    isTextTone: getFromLocalStorage('textTone') == '1',
    theme
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <Form>
        <PageBody>
          <PageMain>
            <Wrapper direction="column" gap={16}>
              <div>
                <Title.H3 style={{marginBottom: 12}}>{t`Chat`}</Title.H3>

                <Wrapper direction="column" gap={8}>
                  <IsTextTone />
                </Wrapper>
              </div>

              <div>
                <Title.H3 style={{marginBottom: 12}}>{t`Appearance`}</Title.H3>
                <Theme />
              </div>
            </Wrapper>
          </PageMain>
        </PageBody>

        <PageFooter>
          <SaveButton>{t`Save`}</SaveButton>
        </PageFooter>
      </Form>
    </Formik>
  );
};

export default Personal;
