import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {MultiSelect} from 'components';
import {useOptions} from './hooks';

const AccessInstances = () => {
  const [field, , helpers] = useField<string>('access_instances');
  const {t} = useTranslation();
  const options = useOptions();

  const value = field.value
    ? JSON.parse(field.value)
    : options.map(item => item.value);

  return (
    <MultiSelect
      {...field}
      onChange={value => helpers.setValue(JSON.stringify(value))}
      options={options}
      placeholder={t`Available numbers`}
      value={value}
    />
  );
};

export default AccessInstances;
