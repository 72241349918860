import React, {useRef, useState} from 'react';
import {findPosition, useOnClickOutside, useOnKeyDown} from 'whatcrm-core';
import cn from 'classnames';

import {Icons} from 'components';

interface Option<T> {
  icon: string;
  value: T;
}

interface SingleSelectAlt<T> {
  onChange: (arg: T) => void;
  options: Option<T>[];
  value: T;
}

const SingleSelectAlt = <T,>({
  onChange,
  options,
  value
}: SingleSelectAlt<T>) => {
  const [isActive, setIsActive] = useState(false);
  const [position, setPosition] = useState<React.CSSProperties>();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const fieldRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(fieldRef, {onClick: () => setIsActive(false)});

  useOnKeyDown(
    e => {
      if (e.code == 'Escape') setIsActive(false);
    },
    [isActive]
  );

  const handleClick = () => {
    const res = findPosition(dropdownRef, {triggerRef: fieldRef});
    const fieldRect = fieldRef.current?.getBoundingClientRect();
    const height = fieldRect?.height || 0;
    const top = res.top + height + 8;

    setPosition({...res, top});
    setIsActive(true);
  };

  const selected = options.find(option => option.value == value);

  return (
    <div className={cn('select-alt', {'select-alt_active': isActive})}>
      <div className="select-alt__field" ref={fieldRef} onClick={handleClick}>
        <svg className="select-alt__label">
          <use href={selected?.icon} />
        </svg>

        <Icons.CaretDown color="green" size={20} />
      </div>

      <div className="select-alt__overlay">
        <div
          className="select-alt__dropdown"
          ref={dropdownRef}
          style={position}
        >
          {options.map((option, i) => (
            <div
              className={cn('select-alt__option', {
                'select-alt__option_active': option.value == value
              })}
              key={i}
              onClick={() => onChange(option.value)}
            >
              <svg className="select-alt__label">
                <use href={option.icon} />
              </svg>

              <div className="select-alt__checkbox">
                <Icons.Check color="white" size={16} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleSelectAlt;
