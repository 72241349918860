import {ContextMenuOption} from 'components';
import * as AppEnv from 'app-env';

import useClear from './use-clear/use-clear';
import usePin from './use-pin/use-pin';
import useRead from './use-read/use-read';

const useMenuOptions = (
  dialog: AppEnv.Dialog,
  setIsConfirmationActive: AppEnv.SetState<boolean>
): ContextMenuOption[] => {
  const clear = useClear(dialog, setIsConfirmationActive);
  const pin = usePin(dialog);
  const read = useRead(dialog);

  const options = [pin, read, clear].filter(option => option != null);
  return options as ContextMenuOption[];
};

export default useMenuOptions;
