import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Placeholder,
  Table,
  TableBody,
  TableData,
  TableHead,
  Text
} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Manager from './manager/manager';
import PermissionsContext from './access-context';

interface Permissions {
  isAccess: boolean;
  managerList: AppEnv.Manager[];
  setManagerList: AppEnv.SetState<AppEnv.Manager[] | undefined>;
}

const Access = ({isAccess, managerList, setManagerList}: Permissions) => {
  const {user} = useWorkspaceContext();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Access control`} - Whatcrm Web`;
  }, []);

  const {integration} = user;

  const fusedManagerList = managerList.filter(
    manager => manager.email != integration.domain
  );

  return (
    <PermissionsContext.Provider value={{setManagerList}}>
      {isAccess ? (
        <>
          {fusedManagerList.length > 0 ? (
            <>
              <Text size={14} style={{marginBottom: 8}}>
                {t`Auto save enabled.`}
              </Text>

              <Table>
                <TableHead>
                  <TableData />
                  <TableData>{t`Chats`}</TableData>
                  <TableData>{t`Messages`}</TableData>
                  <TableData>{t`Connections`}</TableData>
                  <TableData>{t`Staff`}</TableData>

                  {integration.is_partner > 0 && (
                    <TableData>{t`Partner program`}</TableData>
                  )}

                  {integration.is_integration > 0 && (
                    <TableData>{t`Integration`}</TableData>
                  )}

                  <TableData>{t`Billing`}</TableData>
                  <TableData>{t`Templates`}</TableData>
                  <TableData>{t`Blocked chats`}</TableData>
                  {/* <TableData>{t`Widget`}</TableData> */}
                  <TableData>{t`Products`}</TableData>
                  <TableData>{t`Settings`}</TableData>
                </TableHead>

                <TableBody>
                  {fusedManagerList.map(manager => (
                    <Manager key={manager.id} manager={manager} />
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <Placeholder isMaxHeight title={t`No available staff members`} />
          )}
        </>
      ) : (
        <Placeholder isMaxHeight title={t`Access denied`} />
      )}
    </PermissionsContext.Provider>
  );
};

export default Access;
