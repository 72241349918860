import React, {forwardRef, useState} from 'react';
import {LongPressReactEvents, useLongPress} from 'use-long-press';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';

import {Ack, Text, Wrapper} from 'components';
import {
  getDialogId,
  getDialogProfileImage,
  getIsLimited,
  getIsMessageOutgoing
} from 'common/actions';
import {useChatContext} from '../../../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useGetMessageSenderName} from 'common/hooks';
import {useIsOnline} from '../../../../../hooks';
import {useMessageContext} from '../../message-context';
import {useThreadContext} from '../../../thread-context';
import ProfileImage from 'pages/workspace/pages/messenger/dispatch/components/profile-image/profile-image';

import {getIsMsgEdited} from './actions';
import ContextMenuProvider from './menu/menu';
import CtwaContext from './ctwa-context/ctwa-context';
import InnerMessage from '../inner-message/inner-message';
import QuotedMessage from './quoted-message/quoted-message';
import Reactions from './reactions/reactions';

interface TriggerPosition {
  left: number;
  top: number;
}

interface UserMessage {
  children: React.ReactNode;
}

const UserMessage = forwardRef<HTMLDivElement, UserMessage>(
  ({children}, ref) => {
    const {instance} = useDispatchContext();
    const {dialog} = useChatContext();
    const {isThread} = useThreadContext();
    const {message} = useMessageContext();

    const [isContextMenuActive, setIsContextMenuActive] = useState(false);
    const [isReactionPickerActive, setIsReactionPickerActive] = useState(false);
    const [triggerPosition, setTriggerPosition] = useState<TriggerPosition>();

    const {t} = useTranslation();

    const bindLongPress = useLongPress(e => handleContextMenu(e), {
      cancelOnMovement: true,
      filterEvents: e => {
        const target = e.target as HTMLElement;

        const isAttr = target.hasAttribute('data-rmiz-modal-content');
        const isStories = target.classList.contains('stories');

        return !(isAttr || isStories);
      }
    });

    const getMessageSenderName = useGetMessageSenderName();
    const isOnline = useIsOnline();

    const getIsForwarded = () => {
      if ('senderId' in message) return 'forwardInfo' in message;
      else if ('_data' in message) return message.isForwarded;
    };

    const getTimestamp = () => {
      if ('author_id' in message) return message.created;
      else if ('sender_id' in message)
        return new Date(message.timestamp).getTime() / 1000;
      else if ('senderId' in message || 'from' in message)
        return message.timestamp;
    };

    const handleContextMenu = (
      e: MouseEvent | LongPressReactEvents<Element>
    ) => {
      if ((e.target as HTMLElement).hasAttribute('srcset')) return;
      else if ('pageX' in e) setTriggerPosition({left: e.pageX, top: e.pageY});

      setIsContextMenuActive(true);
      e.preventDefault();
    };

    const isActive = isContextMenuActive || isReactionPickerActive;
    const isEdited = getIsMsgEdited(message);
    const isForwarded = getIsForwarded();
    const isLimited = getIsLimited(instance);
    const isOutgoing = getIsMessageOutgoing(dialog, message);
    const isOnlineTag = isOnline && !isOutgoing;
    const senderName = getMessageSenderName(dialog, message);

    const src = isOutgoing
      ? instance.profileImage
      : getDialogProfileImage(instance, dialog);

    const timestamp = getTimestamp();
    const isTime = !isThread && !!timestamp;

    return (
      <div
        {...bindLongPress()}
        className={cn('message', {
          message_active: isActive,
          message_outgoing: isOutgoing,
          message_thread: isThread
        })}
        data-id={message.id.toString()}
        onContextMenu={handleContextMenu}
        ref={ref}
      >
        <ProfileImage
          id={isOutgoing ? instance.id : getDialogId(dialog)}
          name={senderName}
          src={src}
          tag={isOnlineTag ? 'online' : undefined}
          visibility={isThread ? 'hidden' : undefined}
        />

        <div className="message__wrapper">
          <Text
            className="message__sender"
            size={16}
            fontWeight={600}
            style={{marginBottom: 4}}
          >
            {senderName}
          </Text>

          <div
            className={cn('message__bubble', {
              message__bubble_edited: isEdited
            })}
          >
            <div style={{overflow: 'hidden'}}>
              <Wrapper direction="column" gap={12} style={{overflow: 'hidden'}}>
                <QuotedMessage message={message} />

                <div style={{maxWidth: '100%'}}>
                  {isForwarded ? (
                    <InnerMessage>{children}</InnerMessage>
                  ) : (
                    <Wrapper
                      direction="column"
                      gap={8}
                      style={{overflow: 'hidden'}}
                    >
                      {children}
                      {'_data' in message && <CtwaContext message={message} />}
                    </Wrapper>
                  )}
                </div>
              </Wrapper>

              {isEdited && <Text color="secondary" size={12}>{t`Edited`}</Text>}
            </div>

            {isOutgoing && (
              <Ack dialog={dialog} instance={instance} message={message} />
            )}
          </div>

          {!isLimited && <Reactions message={message} />}

          {isTime && (
            <Text className="message__time" size={12}>
              {moment.unix(timestamp).format('LT')}
            </Text>
          )}
        </div>

        <ContextMenuProvider
          isContextMenuActive={isContextMenuActive}
          isReactionPickerActive={isReactionPickerActive}
          setIsContextMenuActive={setIsContextMenuActive}
          setIsReactionPickerActive={setIsReactionPickerActive}
          triggerPosition={triggerPosition}
        />
      </div>
    );
  }
);

UserMessage.displayName = 'UserMessage';
export default UserMessage;
