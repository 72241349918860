import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {PageBody, PageMain, Placeholder} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import PartnerAdd from './partner-add/partner-add';
import PartnerInfo from './partner-info/partner-info';

const Partner = () => {
  const {user} = useWorkspaceContext();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Partner settings`} - Whatcrm Web`;
  }, []);

  const {manager} = user;
  const {partner = {}} = 'partner' in user ? user : {};
  const {settings} = manager.roles;

  return (
    <>
      {settings.read ? (
        Object.keys(partner || {}).length ? (
          <PartnerInfo />
        ) : (
          <PartnerAdd />
        )
      ) : (
        <PageBody>
          <PageMain>
            <Placeholder isMaxHeight title={t`Access denied`} />
          </PageMain>
        </PageBody>
      )}
    </>
  );
};

export default Partner;
