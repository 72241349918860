import React from 'react';
import {useTranslation} from 'react-i18next';

import {TableData, TableRow} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Dialogs from './dialogs/dialogs';
import ManagerContext from './manager-context';
import Messages from './messages/messsages';
import Permit from './permit/permit';
import Settings from './settings/settings';

interface Manager {
  manager: AppEnv.Manager;
}

const Manager = ({manager}: Manager) => {
  const {user} = useWorkspaceContext();
  const {t} = useTranslation();
  const {integration} = user;

  const getLabel = (str: string) => `${manager.fullname} • ${str}`;

  return (
    <ManagerContext.Provider value={{manager}}>
      <TableRow>
        <TableData isBackground>{manager.fullname}</TableData>

        <TableData>
          <Dialogs getLabel={getLabel} />
        </TableData>

        <TableData>
          <Messages getLabel={getLabel} />
        </TableData>

        <TableData>
          <Permit label={getLabel(t`Connections`)} roleKey="instances" />
        </TableData>

        <TableData>
          <Permit label={getLabel(t`Staff`)} roleKey="managers" />
        </TableData>

        {integration.is_partner > 0 && (
          <TableData>
            <Permit label={getLabel(t`Partner program`)} roleKey="partners" />
          </TableData>
        )}

        {integration.is_integration > 0 && (
          <TableData>
            <Permit label={getLabel(t`Integration`)} roleKey="integrators" />
          </TableData>
        )}

        <TableData>
          <Permit label={getLabel(t`Billing`)} roleKey="billing" />
        </TableData>

        <TableData>
          <Permit label={getLabel(t`Templates`)} roleKey="templates" />
        </TableData>

        <TableData>
          <Permit label={getLabel(t`Blocked chats`)} roleKey="blacklist" />
        </TableData>

        {/* <TableData>
          <Permit label={getLabel(t`Widget`)} roleKey="widget" />
        </TableData> */}

        <TableData>
          <Permit label={getLabel(t`Products`)} roleKey="products" />
        </TableData>

        <TableData>
          <Settings getLabel={getLabel} />
        </TableData>
      </TableRow>
    </ManagerContext.Provider>
  );
};

export default Manager;
