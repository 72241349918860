import React from 'react';
import {useTranslation} from 'react-i18next';
import mime from 'mime';

import {ContextMenuOption, IconsAlt} from 'components';
import {downloadFile, getBlob} from 'common/actions';
import * as AppEnv from 'app-env';

const useDownload = (msg: AppEnv.Message): ContextMenuOption | null => {
  const {t} = useTranslation();

  const getFileUrl = () => {
    if ('sender_id' in msg) return msg.file_url;
    else if ('senderId' in msg || '_data' in msg) return msg.body;

    return null;
  };

  const handleClick = async () => {
    const fileUrl = getFileUrl();
    if (!fileUrl) return;

    const blob = await getBlob(fileUrl);
    downloadFile(blob || fileUrl, '', !blob);
  };

  if (!('sender_id' in msg) && !('senderId' in msg) && !('_data' in msg))
    return null;

  const isChatImage =
    'sender_id' in msg &&
    msg.type == 'file' &&
    mime.getType(msg.file_url)?.startsWith('image');

  const isImage = (msg.type == 'photo' || msg.type == 'image') && msg.body;

  if (!isChatImage && !isImage) return null;

  const download: ContextMenuOption = {
    icon: <IconsAlt.DownloadSimple />,
    label: t`Download`,
    onClick: () => handleClick()
  };

  return download;
};

export default useDownload;
