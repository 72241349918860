import React from 'react';
import {Form, Formik} from 'formik';
import {isolateNumbers} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Button,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Text,
  Wrapper
} from 'components';
import {Label} from '../../../components';
import {useConnectorContext} from '../../connector-context';
import {useInstanceContext} from '../../../instance-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Phone from './phone/phone';

interface Values {
  phone: string;
}

interface PhoneNumber {
  setCode: AppEnv.SetState<string | undefined>;
  setIsQrCodeMethod: AppEnv.SetState<boolean>;
}

const getMessenger = (instance: AppEnv.Instance) =>
  instance.version == 'telegram' ? 'Telegram' : 'Messenger';

const PhoneNumber = ({setCode, setIsQrCodeMethod}: PhoneNumber) => {
  const {instance} = useInstanceContext();
  const {getStatus} = useConnectorContext();

  const {fetchDestroy, fetchSetAuthenticationPhoneNumber} = useRequest();
  const {t} = useTranslation();

  const setAuthenticationPhoneNumber = async (values: Values) => {
    const res = await fetchSetAuthenticationPhoneNumber(
      instance.chat_key,
      isolateNumbers(values.phone)
    );

    if (res?.code) setCode(res.code);
    return true;
  };

  const submitForm = async (values: Values) => {
    if (instance.version != 'telegram')
      return setAuthenticationPhoneNumber(values);

    const res = await fetchDestroy(instance.chat_key);
    if (!res) return true;

    await getStatus();
    return setAuthenticationPhoneNumber(values);
  };

  const {condition} = instance;
  const initialValues: Values = {phone: ''};
  const messenger = getMessenger(instance);

  const validationSchema = yup
    .object()
    .shape({phone: yup.string().trim().required()});

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={submitForm}
    >
      <Form>
        <PopupWrapper>
          <Label style={{marginBottom: 12}} />

          <Text size={16} style={{marginBottom: 4}}>
            {t`Enter your phone number`}
          </Text>

          <Text size={14} style={{marginBottom: 16}}>
            {instance.version == 'whatcrm'
              ? t`WhatsApp will generate a code for that number.`
              : t('{{messenger}} will send you a code.', {messenger})}
          </Text>

          <Phone />
        </PopupWrapper>

        <PopupFooter>
          <Wrapper
            alignItems="flex-end"
            gap={12}
            justifyContent="space-between"
          >
            <SaveButton>{t`Continue`}</SaveButton>

            <Button
              color="transparent"
              onClick={() => setIsQrCodeMethod(true)}
              state={condition?.qrCode ? undefined : 'disabled'}
            >
              {t`Use QR code`}
            </Button>
          </Wrapper>
        </PopupFooter>
      </Form>
    </Formik>
  );
};

export default PhoneNumber;
