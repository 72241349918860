import React, {forwardRef} from 'react';
import cn from 'classnames';

import {Icons, Text, Tooltip} from 'components';

type Color =
  | 'blue-dash'
  | 'fade'
  | 'green'
  | 'green-border'
  | 'translucent'
  | 'transparent';

export interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  color?: Color;
  isSubmit?: boolean;
  minHeight?: 32 | 40;
  onClick?: () => void;
  state?: 'active' | 'disabled' | 'submitting';
  style?: React.CSSProperties;
  tooltip?: string;
  width?: '100%';
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      color = 'green',
      isSubmit,
      minHeight,
      onClick = () => {},
      state,
      style,
      tooltip,
      width
    },
    ref
  ) => {
    const isDisabled = state == 'disabled' || state == 'submitting';
    const isProgress = color != 'transparent' && state == 'submitting';

    return (
      <button
        className={cn('btn', {
          [`${className}`]: className,
          [`btn_${color}`]: color,
          [`btn_${state}`]: state,
          btn_icon: typeof children == 'object'
        })}
        ref={ref}
        style={{...style, minHeight, width}}
        type={isSubmit ? 'submit' : 'button'}
        disabled={isDisabled}
        onClick={onClick}
      >
        {children}

        {isProgress && (
          <Icons.CircleNotch
            className="btn__progress"
            color="white"
            size={24}
          />
        )}

        {tooltip && (
          <Tooltip>
            <Text color="white" size={14}>
              {tooltip}
            </Text>
          </Tooltip>
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export {default as SaveButton} from './save-button/save-button';
export default Button;
