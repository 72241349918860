import React from 'react';
import {useTranslation} from 'react-i18next';

import {getUrlParams} from 'common/actions';
import {Icons, Text, Wrapper} from 'components';

const Error = () => {
  const {t} = useTranslation();
  const {error_text} = getUrlParams();

  return (
    <Wrapper gap={8}>
      <Icons.WarningCircle size={24} />

      <div>
        <Text fontWeight={600} size={16}>
          {t`Error`}
        </Text>

        <Text size={14}>{error_text}</Text>
      </div>
    </Wrapper>
  );
};

export default Error;
