import React from 'react';
import cn from 'classnames';

import Content, {ContentParams} from './content/content';

export interface ItemParams extends ContentParams {
  onClick?: () => void;
}

const Item = (props: ItemParams) => {
  const {href, onClick, type} = props;
  const params = {children: <Content {...props} />, onClick};

  return (
    <>
      {href ? (
        <a
          {...params}
          className="profile__item"
          href={href}
          rel="noreferrer"
          target="_blank"
        />
      ) : (
        <div
          {...params}
          className={cn('profile__item', {
            profile__item_block: type == 'ban'
          })}
        />
      )}
    </>
  );
};

export default Item;
