import React, {useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, ContextMenu, Icons} from 'components';
import {getIsLimited} from 'common/actions';
import {useChatContext} from '../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useFooterContext} from '../../footer-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import Attachs from './attachs/attachs';
import Poll from './poll/poll';
import Template from './template/template';

const PaperClip = () => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg} = useChatContext();
  const {setIsPollActive, setTemplateState} = useFooterContext();

  const [isContextMenuActive, setIsContextMenuActive] = useState(false);

  const {t} = useTranslation();
  const attachsRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const {manager} = user;
  const {messages, templates} = manager.roles;

  const isLimited = getIsLimited(instance);
  const options = [];

  if (templates.read)
    options.push({
      icon: <Icons.Note size={24} />,
      label: t`Template`,
      onClick: () => setTemplateState({isActive: true})
    });

  if (messages.file)
    options.push({
      icon: <Icons.ImageSquare size={24} />,
      label: t`File`,
      onClick: () => attachsRef.current?.click()
    });

  if (dialog.version == 'whatcrm' && !isLimited && messages.polls)
    options.unshift({
      icon: <Icons.ChartBarHorizontal size={24} />,
      label: t`Poll`,
      onClick: () => setIsPollActive(true)
    });

  return (
    <>
      {options.length > 0 && (
        <Button
          ref={triggerRef}
          color="transparent"
          onClick={() => setIsContextMenuActive(prevValue => !prevValue)}
          state={quotedMsg?.isEditing ? 'disabled' : undefined}
        >
          <Icons.PaperClip />
        </Button>
      )}

      <ContextMenu
        triggerRef={triggerRef}
        options={options}
        isActive={isContextMenuActive}
        isCustomPosition
        setIsActive={setIsContextMenuActive}
      />

      <Attachs ref={attachsRef} />
      <Poll />
      <Template />
    </>
  );
};

export default PaperClip;
