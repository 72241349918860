import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import * as AppEnv from 'app-env';

import useCopyMsg from './use-copy-msg/use-copy-msg';

const useCopy = (msg: AppEnv.Message): ContextMenuOption => {
  const {t} = useTranslation();
  const copyMsg = useCopyMsg(msg);

  const copy: ContextMenuOption = {
    label: t`Copy`,
    icon: <IconsAlt.Files />,
    onClick: copyMsg
  };

  return copy;
};

export default useCopy;
