import {ContextMenuOption} from 'components';
import * as AppEnv from 'app-env';

import useCopy from './use-copy/use-copy';
import useDel from './use-del/use-del';
import useDownload from './use-download/use-download';
import useEdit from './use-edit/use-edit';
import useForward from './use-forward/use-forward';
import useReply from './use-reply/use-reply';

interface UseOptions {
  msg: AppEnv.Message;
  setIsConfirmActive: AppEnv.SetState<boolean>;
  setIsForwardActive: AppEnv.SetState<boolean>;
}

const useOptions = ({
  msg,
  setIsConfirmActive,
  setIsForwardActive
}: UseOptions): ContextMenuOption[] => {
  const copy = useCopy(msg);
  const del = useDel(msg, setIsConfirmActive);
  const download = useDownload(msg);
  const edit = useEdit(msg);
  const forward = useForward(msg, setIsForwardActive);
  const reply = useReply();

  const options = [copy, reply, edit, download, forward, del].filter(
    option => option != null
  );

  return options as ContextMenuOption[];
};

export default useOptions;
