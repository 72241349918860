import React from 'react';
import cn from 'classnames';

import {useTariffListContext} from '../../tariff-list-context';
import {useTariffsContext} from '../../../tariffs-context';
import * as AppEnv from 'app-env';

import Quantity from './quantity/quantity';
import Summa from './summa/summa';

interface TariffOptions {
  tariffOptions: AppEnv.Tariff[];
}

const TariffOptions = ({tariffOptions}: TariffOptions) => {
  const {isWrite} = useTariffsContext();
  const {selectedTariff, setSelectedTariff} = useTariffListContext();

  return (
    <div className="tariff__options">
      {tariffOptions.map(tariffOption => (
        <button
          className={cn('tariff__options-item', {
            'tariff__options-item_active': tariffOption.id == selectedTariff?.id
          })}
          disabled={!isWrite}
          key={tariffOption.id}
          onClick={() => setSelectedTariff(tariffOption)}
        >
          <Quantity tariff={tariffOption} />
          <Summa tariff={tariffOption} />
        </button>
      ))}
    </div>
  );
};

export default TariffOptions;
