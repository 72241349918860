import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {MultiSelect, SelectOption} from 'components';
import {useManagerContext} from '../manager-context';
import {useRequest} from 'common/hooks';
import {useUpdManagerRoles} from '../hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

type Value = 'block' | 'delete' | 'pin' | 'read';

interface Dialogs {
  getLabel: (str: string) => string;
}

const Dialogs = ({getLabel}: Dialogs) => {
  const {user} = useWorkspaceContext();
  const {manager} = useManagerContext();

  const {postManagersUpdate} = useRequest();
  const {t} = useTranslation();
  const updManagerRoles = useUpdManagerRoles();

  const handleChange = async (value: Value[]) => {
    const {integration} = user;

    const newValue: AppEnv.ManagerRolesDialogs = {
      block: value.includes('block'),
      delete: value.includes('delete'),
      pin: value.includes('pin'),
      read: value.includes('read'),
      write: true
    };

    const newRoles = upd(manager.roles, {dialogs: {$set: newValue}});
    updManagerRoles(newRoles);

    const res = await postManagersUpdate(integration.id, manager.id, {
      roles: newRoles
    });

    if (res) return;
    updManagerRoles(manager.roles);
  };

  const {roles} = manager;

  const options: SelectOption<Value>[] = [
    {label: t`Pin`, value: 'pin'},
    {label: t`Mark as read`, value: 'read'},
    {label: t`Delete`, value: 'delete'},
    {label: t`Block`, value: 'block'}
  ];

  const value = Object.entries(roles.dialogs).reduce((acc, [key, value]) => {
    if (
      (key == 'block' || key == 'delete' || key == 'pin' || key == 'read') &&
      value
    )
      acc.push(key);

    return acc;
  }, [] as Value[]);

  return (
    <MultiSelect
      isAlt
      maxWidth={160}
      onChange={handleChange}
      optgroup={getLabel(t`Chats`)}
      options={options}
      placeholder={t`Read-only`}
      value={value}
    />
  );
};

export default Dialogs;
