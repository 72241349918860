import {formatPhoneNumber} from 'whatcrm-core';

import {SelectOption} from 'components';
import {useGetInstanceLabel} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useOptions = () => {
  const {user} = useWorkspaceContext();
  const getInstanceLabel = useGetInstanceLabel();

  const getComment = (instance: AppEnv.Instance) => {
    const labels = Object.values(user.instances)
      .filter(item => item.phone == instance.phone)
      .map(item => getInstanceLabel(item));

    const comment = labels.join(', ');
    return comment;
  };

  const options = Object.values(user.instances).reduce((acc, instance) => {
    if (!instance.phone) return acc;
    else if (acc.some(item => item.value == instance.phone)) return acc;

    const comment = getComment(instance);

    acc.push({
      comment,
      label: formatPhoneNumber(instance.phone),
      value: instance.phone
    });

    return acc;
  }, [] as SelectOption<string>[]);

  return options;
};

export default useOptions;
