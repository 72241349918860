import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useMessageContext} from '../../../../../message-context';
import * as AppEnv from 'app-env';

interface QuotedMsg {
  isStory: boolean;
  quotedMsg?: AppEnv.AvitoQuoteMessage | AppEnv.Message;
}

const useQuotedMsg = (): QuotedMsg => {
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();
  const {message} = useMessageContext();

  let isStory = false;

  if ('quote' in message) {
    return {isStory, quotedMsg: message.quote};
  } else if ('quotedMsgId' in message) {
    if (dialog.version != 'chat') return {isStory};

    const quotedMsg = dialog.messages?.find(
      msg => msg.id == message.quotedMsgId
    );

    return {isStory, quotedMsg};
  } else if ('quotedMsg' in message) {
    const {quotedMsg} = message;

    if (instance.version == 'telegram' && quotedMsg) {
      (quotedMsg as AppEnv.TelegramMessage).senderId = null;
      if ('senderId' in quotedMsg && quotedMsg._ == 'story') isStory = true;
    }

    return {isStory, quotedMsg};
  }

  return {isStory};
};

export default useQuotedMsg;
