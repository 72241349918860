import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {Placeholder, Table, TableBody, TableData, TableHead} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Manager from './manager/manager';
import ManagerCreate from './manager-create/manager-create';
import ManagerSync from './manager-sync/manager-sync';

interface StaffMain {
  getManagerList: () => Promise<void>;
  managerList: AppEnv.Manager[];
  setManagerList: AppEnv.SetState<AppEnv.Manager[] | undefined>;
}

const StaffMain = ({
  getManagerList,
  managerList,
  setManagerList
}: StaffMain) => {
  const {user} = useWorkspaceContext();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Staff`} - Whatcrm Web`;
  }, []);

  const {integration, manager} = user;
  const {managers} = manager.roles;

  return (
    <>
      {managers.write && (
        <>
          {integration.type == 'LK' ? (
            <ManagerCreate setManagerList={setManagerList} />
          ) : (
            <ManagerSync getManagerList={getManagerList} />
          )}
        </>
      )}

      {managerList.length ? (
        <Table>
          <TableHead>
            <TableData>{t`Name`}</TableData>
            <TableData>{t`Email`}</TableData>
            <TableData>{t`Personal phone number`}</TableData>
            <TableData>{t`Available numbers`}</TableData>

            {managers.write && (
              <>
                <TableData />
                <TableData />
              </>
            )}
          </TableHead>

          <TableBody>
            {managerList.map(manager => (
              <Manager
                key={manager.id}
                manager={manager}
                setManagerList={setManagerList}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Placeholder isMaxHeight title={t`No staff members`} />
      )}
    </>
  );
};

export default StaffMain;
