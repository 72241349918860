import React from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {TableData, TableRow} from 'components';
import {useTariffPeriod} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Payment {
  isDeleted: boolean;
  payment: AppEnv.Payment;
}

const Payment = ({isDeleted, payment}: Payment) => {
  const {user} = useWorkspaceContext();

  const {i18n, t} = useTranslation();
  const period = useTariffPeriod(payment.tariff_quantity);

  const {integration} = user;

  return (
    <TableRow>
      <TableData>
        {moment.unix(payment.date_yslug).utcOffset(3).format('L LT')}
      </TableData>

      <TableData>{payment.tariff_plane || 'Start'}</TableData>
      <TableData>{period}</TableData>

      <TableData>
        {(+payment.summa).toLocaleString(i18n.resolvedLanguage, {
          currency: payment.currency || 'usd',
          style: 'currency'
        })}
      </TableData>

      {integration.is_partner > 0 && <TableData>{payment.domain}</TableData>}

      {isDeleted && (
        <TableData>{payment.is_deleted > 0 && t`Refund processed`}</TableData>
      )}
    </TableRow>
  );
};

export default Payment;
