import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface ManagerContext {
  manager: AppEnv.Manager;
}

const ManagerContext = React.createContext<ManagerContext>(
  {} as ManagerContext
);

export const useManagerContext = () => useContext(ManagerContext);
export default ManagerContext;
