import React, {forwardRef} from 'react';
import cn from 'classnames';

interface Wrapper {
  alignItems?: 'center' | 'flex-end' | 'stretch';
  alignSelf?: 'flex-start';
  children: React.ReactNode;
  direction?: 'column' | 'row-reverse';
  flexWrap?: 'nowrap' | 'resp-wrap';
  gap?: 2 | 4 | 6 | 8 | 12 | 16 | 24 | 48;
  isFlexGrow?: true;
  justifyContent?: 'center' | 'space-between';
  style?: React.CSSProperties;
}

const Wrapper = forwardRef<HTMLDivElement, Wrapper>(
  (
    {
      alignItems,
      alignSelf,
      children,
      direction,
      flexWrap,
      gap,
      isFlexGrow,
      justifyContent,
      style
    },
    ref
  ) => {
    const htmlStyle: React.CSSProperties = {
      ...style,
      flexWrap: flexWrap != 'resp-wrap' ? flexWrap : undefined
    };

    return (
      <div
        className={cn('wrapper', {
          'wrapper_flex-grow': isFlexGrow,
          'wrapper_resp-wrap': flexWrap == 'resp-wrap',
          [`wrapper_align-items-${alignItems}`]: alignItems,
          [`wrapper_align-self-${alignSelf}`]: alignSelf,
          [`wrapper_direction-${direction}`]: direction,
          [`wrapper_gap-${gap}`]: gap,
          [`wrapper_justify-content-${justifyContent}`]: justifyContent
        })}
        ref={ref}
        style={htmlStyle}
      >
        {children}
      </div>
    );
  }
);

Wrapper.displayName = 'Wrapper';
export default Wrapper;
