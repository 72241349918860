import React, {useState} from 'react';
import {formatPhoneNumber, isolateNumbers} from 'whatcrm-core';
import {parseVCards} from 'vcard4-ts';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {Button, Wrapper} from 'components';
import {MessageText, UserMessage} from '../../components';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';
import ProfileImage from 'pages/workspace/pages/messenger/dispatch/components/profile-image/profile-image';

const MultiVcard = () => {
  const {instance} = useDispatchContext();
  const {message} = useWhatsAppMessageContext();

  const [contactProfileImages, setContactProfileImages] = useState<
    (string | null)[]
  >([]);

  const [isOpen, setIsOpen] = useState(false);

  const {fetchProfileImage} = useRequest();
  const {t} = useTranslation();

  const contacts =
    message.vCards?.map(item => {
      const {vCards} = parseVCards(item);

      return {
        name: vCards?.[0].FN[0].value,
        phoneNumber: vCards?.[0].TEL?.[0].value
          ? isolateNumbers(vCards[0].TEL[0].value)
          : undefined
      };
    }) || [];

  const getContactProfileImage = async (id: string, index: number) => {
    const res = await fetchProfileImage(instance.chat_key, id);

    setContactProfileImages(prevValue =>
      update(prevValue, {[index]: {$set: res}})
    );
  };

  const getProfileImages = () => {
    const profileImages = [];

    for (let i = 0; i < 3; i++) {
      const item = contacts[i];

      if (item) {
        if (typeof contactProfileImages[i] != 'object') {
          getContactProfileImage(`${item.phoneNumber}@c.us`, i);
        }

        profileImages.push(
          <ProfileImage
            id={item.phoneNumber}
            key={i}
            name={item.name}
            src={contactProfileImages[i]}
          />
        );
      }
    }

    return profileImages;
  };

  return (
    <UserMessage>
      <Wrapper alignItems="center" direction="column" gap={16}>
        <Wrapper alignItems={!isOpen ? 'center' : undefined} gap={8}>
          <div className="multi-vcard__profile-images">
            {getProfileImages()}
          </div>

          {isOpen ? (
            <Wrapper direction="column" gap={8}>
              {contacts.map((item, i) => (
                <Wrapper alignItems="center" gap={8} key={i}>
                  <div>
                    <MessageText>{item.name}</MessageText>

                    {item.phoneNumber && (
                      <MessageText isLight isSmall>
                        {formatPhoneNumber(item.phoneNumber)}
                      </MessageText>
                    )}
                  </div>
                </Wrapper>
              ))}
            </Wrapper>
          ) : (
            <MessageText>
              {contacts[0]?.name}

              {contacts.length > 1 && (
                <> {t('and {{length}} more', {length: contacts.length - 1})}</>
              )}
            </MessageText>
          )}
        </Wrapper>

        <Button
          color="green-border"
          minHeight={32}
          onClick={() => setIsOpen(prevValue => !prevValue)}
          style={{width: 162}}
        >
          {isOpen ? t`Hide` : t`Show all`}
        </Button>
      </Wrapper>
    </UserMessage>
  );
};

export default MultiVcard;
