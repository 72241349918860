import axios from 'axios';
import * as AppEnv from 'app-env';

const getBaseURL = (integration: AppEnv.Integration | undefined) => {
  if (integration?.type == 'PIPEDRIVE') return 'https://pipedrive.whatcrm.net';
};

const useInstance = (integration: AppEnv.Integration | undefined) => {
  const baseURL = getBaseURL(integration);
  if (!baseURL) return null;

  const instance = axios.create({
    baseURL,
    headers: {'Content-Type': 'application/json'}
  });

  return instance;
};

export default useInstance;
