import {formatPhoneNumber, useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {getDialogPhoneNumber} from 'common/actions';
import {ItemParams} from '../../../../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../../../../chat-context';

const usePhoneNumber = (): ItemParams | null => {
  const {setNotification} = useAppContext();
  const {dialog, telegramUser} = useChatContext();

  const copyToClipboard = useCopyToClipboard();
  const {t} = useTranslation();

  const handleClick = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  const phoneNumber = getDialogPhoneNumber(dialog, telegramUser);
  if (!phoneNumber) return null;

  const phoneNumberItem: ItemParams = {
    label: t`phone number`,
    onClick: () => handleClick(phoneNumber),
    type: 'copy',
    value: formatPhoneNumber(phoneNumber)
  };

  return phoneNumberItem;
};

export default usePhoneNumber;
