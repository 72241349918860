import React, {useContext} from 'react';
import * as AppEnv from 'app-env';
import {Integration} from './tariffs-env';

interface TariffsContext {
  instances: AppEnv.Instances | undefined;
  integration: Integration;
  isPartner: boolean;
  isWrite: boolean;
}

const TariffsContext = React.createContext<TariffsContext>(
  {} as TariffsContext
);

export const useTariffsContext = () => useContext(TariffsContext);
export default TariffsContext;
