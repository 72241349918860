import {useLocation} from 'react-router-dom';

const useInitialIsSidebarActive = () => {
  const location = useLocation();

  if (window.screen.width <= 768) return false;
  else if (location.pathname.startsWith('/messenger')) return false;
  else if (location.pathname == '/') return process.env.REACT_APP_TYPE == 'app';

  return true;
};

export default useInitialIsSidebarActive;
