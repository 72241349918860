import React, {forwardRef} from 'react';
import {useFormikContext} from 'formik';

import {Icons, SaveButton, Voice} from 'components';
import {useChatContext} from '../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useFooterContext} from '../footer-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as FooterEnv from '../footer-env';

import Clear from './clear/clear';
import Emoji from './emoji/emoji';
import PaperClip from './paper-clip/paper-clip';
import Text from './text/text';

interface MessageInput {
  textRef: React.RefObject<HTMLTextAreaElement>;
}

const MessageInput = forwardRef<HTMLTextAreaElement, MessageInput>(
  ({textRef}, ref) => {
    const {user} = useWorkspaceContext();
    const {instance} = useDispatchContext();
    const {dialog, quotedMsg} = useChatContext();

    const {isAttachsFormActive, isVoiceRecording, setIsVoiceRecording} =
      useFooterContext();

    const {values} = useFormikContext<FooterEnv.Values>();

    const {manager} = user;
    const {messages} = manager.roles;

    const isSendButtonShown = !isAttachsFormActive && !!values.text;
    const isVoice = dialog.version != 'chat' && messages.voice;

    return (
      <div className="footer__message-input">
        {!isVoiceRecording && (
          <>
            <PaperClip />
            <Text ref={ref} />

            {messages.write ? (
              <Emoji textRef={textRef} />
            ) : (
              values.text && <Clear />
            )}
          </>
        )}

        {isSendButtonShown ? (
          <SaveButton
            color="transparent"
            state={!values.text ? 'disabled' : undefined}
          >
            <Icons.PaperPlaneRight />
          </SaveButton>
        ) : (
          isVoice && (
            <Voice
              isDisabled={quotedMsg?.isEditing}
              isVoiceRecording={isVoiceRecording}
              setIsVoiceRecording={setIsVoiceRecording}
              version={instance.version}
            />
          )
        )}
      </div>
    );
  }
);

MessageInput.displayName = 'MessageInput';
export default MessageInput;
