import React, {useEffect} from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {
  Body,
  Page,
  PageBody,
  PageMain,
  Placeholder,
  Preloader
} from 'components';
import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {getDialogList} from './actions';
import Dispatch from './dispatch/dispatch';
import MessengerContext from './messenger-context';

interface Messenger {
  dialogs: AppEnv.Dialogs | undefined;
  setDialogs: AppEnv.SetState<AppEnv.Dialogs | undefined>;
}

const Messenger = ({dialogs, setDialogs}: Messenger) => {
  const {isIframe} = useAppContext();
  const {activeInstances} = useWorkspaceContext();

  const {instanceId} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      instanceId &&
      activeInstances.find(instance => instance.id == parseInt(instanceId))
    )
      return;

    const {id} = activeInstances?.[0] || {};
    if (!id) return;

    navigate(`/messenger/${id}`, {replace: isIframe});
  }, [instanceId]);

  useEffect(() => {
    document.title = 'Whatcrm Web';
  }, []);

  const instance = instanceId
    ? activeInstances.find(instance => instance.id == parseInt(instanceId))
    : undefined;

  const text = isIframe
    ? `${t`No available connections.`} ${t`Please check the connection status and staff member access.`}`
    : `${t`No available connections.`} ${t`Please check the rate plan, connection status and staff member access.`}`;

  return (
    <MessengerContext.Provider value={{setDialogs}}>
      {process.env.REACT_APP_TYPE == 'app' ? (
        <Navigate replace={isIframe} to="/connections" />
      ) : dialogs ? (
        instance ? (
          <Dispatch
            dialogList={getDialogList(dialogs, instance)}
            instance={instance}
          />
        ) : (
          <Body>
            <Page>
              <PageBody>
                <PageMain>
                  <Placeholder
                    text={text}
                    title="Whatcrm Web"
                    isDelay
                    isMaxHeight
                  />
                </PageMain>
              </PageBody>
            </Page>
          </Body>
        )
      ) : (
        <Body>
          <Page>
            <PageBody>
              <PageMain>
                <Preloader isMaxHeight />
              </PageMain>
            </PageBody>
          </Page>
        </Body>
      )}
    </MessengerContext.Provider>
  );
};

export default Messenger;
