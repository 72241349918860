import React, {useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Body, Nav, Page, PageBody, PageMain, Preloader} from 'components';
import {getUrlParams} from 'common/actions';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Access from './access/access';
import StaffMain from './staff-main/staff-main';

const Staff = () => {
  const {user} = useWorkspaceContext();
  const [managerList, setManagerList] = useState<AppEnv.Manager[]>();

  const {fetchManagersList} = useRequest();
  const {t} = useTranslation();

  const getManagerList = async () => {
    const {integration} = user;
    const res = await fetchManagersList(integration.id);
    setManagerList(res || []);
  };

  useEffect(() => {
    getManagerList();
  }, []);

  const {is_admin} = getUrlParams();
  const {manager} = user;
  const {managers} = manager.roles;

  const isAccess = is_admin != '0' && managers.write;
  const options = [{label: t`Staff`, value: '/staff'}];

  if (isAccess)
    options.push({label: t`Access control`, value: '/staff/access'});

  return (
    <Body>
      <Page>
        <Nav options={options} value={location.pathname} />

        <PageBody>
          <PageMain>
            {managerList ? (
              <Routes>
                <Route
                  path="/"
                  element={
                    <StaffMain
                      getManagerList={getManagerList}
                      managerList={managerList}
                      setManagerList={setManagerList}
                    />
                  }
                />

                <Route
                  path="/access"
                  element={
                    <Access
                      isAccess={isAccess}
                      managerList={managerList}
                      setManagerList={setManagerList}
                    />
                  }
                />
              </Routes>
            ) : (
              <Preloader isMaxHeight />
            )}
          </PageMain>
        </PageBody>
      </Page>
    </Body>
  );
};

export default Staff;
