import {useTranslation} from 'react-i18next';

import {ItemParams} from '../../../../components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useBlock = (
  setIsConfirmActive: AppEnv.SetState<boolean>
): ItemParams | null => {
  const {user} = useWorkspaceContext();
  const {t} = useTranslation();

  const {manager} = user;
  const {dialogs} = manager.roles;

  if (!dialogs.block) return null;

  const block: ItemParams = {
    onClick: () => setIsConfirmActive(true),
    type: 'ban',
    value: t`Block`
  };

  return block;
};

export default useBlock;
