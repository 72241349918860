import React from 'react';

interface OrderedList {
  children: React.ReactNode;
}

const OrderedList = ({children}: OrderedList) => (
  <ol className="ordered-list">{children}</ol>
);

export {default as OrderedListItem} from './ordered-list-item/ordered-list-item';
export default OrderedList;
