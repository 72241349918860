import {useTranslation} from 'react-i18next';
import {v4 as uuid} from 'uuid';
import axios from 'axios';

import {getBaseUrl} from 'common/actions';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

interface AxiosResponse extends Response {
  status?: string;
}

interface Reject {
  code: string;
  response?: AxiosResponse;
}

interface RequestParams {
  baseURL?: string;
  body?: object;
  data?: object;
  isFormData?: boolean;
  method?: 'get' | 'post';
  noNotification?: boolean;
  params?: object;
  timeout?: number;
}

interface Response {
  data: any; // eslint-disable-line
}

const useRequest = () => {
  const {setNotification = () => {}} = useAppContext();
  const {i18n, t} = useTranslation();

  const request = async <T>(
    path: string,
    {
      baseURL = getBaseUrl().apiUrl,
      data,
      isFormData,
      method = 'get',
      noNotification,
      params,
      timeout
    }: RequestParams = {}
  ): Promise<T | null> => {
    const controller = new AbortController();

    const instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
        'X-Whappim-Token': process.env.REACT_APP_API_TOKEN
      },
      signal: controller.signal,
      params: {lang: i18n.resolvedLanguage}
    });

    if (timeout) setTimeout(() => controller.abort(), timeout);

    const res = await instance(path, {data, method, params})
      .then((response: Response) => {
        if (
          typeof response.data != 'object' ||
          (!('error_code' in response.data) && !('error_text' in response.data))
        )
          return response.data;

        const errorCode = response.data.error_code
          ? ` ${response.data.error_code}`
          : '';

        if (!noNotification)
          setNotification({
            title: t('Error{{errorCode}}', {errorCode}),
            text: response.data.error_text
          });

        return null;
      })
      .catch(({code, response}: Reject) => {
        if (code == 'ERR_CANCELED') return null;

        let errorCode = response?.data.error_code || response?.status || '';
        errorCode = errorCode ? ` ${errorCode}` : '';

        if (!noNotification)
          setNotification({
            title: t('Error{{errorCode}}', {errorCode}),
            text: response?.data.error_text || t`Something went wrong.`
          });

        return null;
      });

    return res;
  };

  interface FetchBlacklistDelete {
    success: 0 | 1;
  }

  const fetchBlacklistDelete = (integration_id: number, id: number) =>
    request<FetchBlacklistDelete>('v3/blacklist/delete', {
      params: {id, integration_id}
    });

  interface FetchChatFree {
    success: 0 | 1;
  }

  const fetchChatFree = (id: string) =>
    request<FetchChatFree>('v3/chat/free', {params: {id}});

  const fetchChatInfo = (id: string) =>
    request<AppEnv.ChatInfo>('v3/chat/info', {params: {id}});

  const fetchChatRead = (chat_key: string, user_id: string, chatId: string) =>
    request(`v3/instance${chat_key}/chatRead`, {params: {chatId, user_id}});

  const fetchCheckAuthenticationCode = (chat_key: string, code: string) =>
    request(`v3/instance${chat_key}/checkAuthenticationCode`, {params: {code}});

  const fetchCheckAuthenticationPassword = (
    chat_key: string,
    password: string
  ) =>
    request(`v3/instance${chat_key}/checkAuthenticationPassword`, {
      params: {password}
    });

  const fetchCreatePrivateChat = (chat_key: string, chatId: number) =>
    request<AppEnv.TelegramDialog>(`v3/instance${chat_key}/createPrivateChat`, {
      noNotification: true,
      params: {chatId}
    });

  const fetchDashboard = (crm: string, domain: string) =>
    request<AppEnv.Dashboard>('v3/dashboard', {params: {crm, domain}});

  const fetchDeleteMessage = (
    chat_key: string,
    chatId: number | string,
    messageId: number | string,
    revoke: boolean
  ) =>
    request<null>(`v3/instance${chat_key}/deleteMessage`, {
      params: {chatId, messageId, revoke: revoke ? 1 : 0}
    });

  const fetchDeleteMessageAvito = (
    chat_key: string,
    user_id: string,
    chat_id: string,
    message_id: string
  ) =>
    request(`v3/instance${chat_key}/deleteMessage`, {
      params: {chat_id, message_id, user_id}
    });

  const fetchDestroy = (chat_key: string) =>
    request(`v3/instance${chat_key}/destroy`);

  const fetchDialog = async (chat_key: string, chatId: number | string) => {
    const res = await request<AppEnv.Dialog>(`v3/instance${chat_key}/dialog`, {
      noNotification: true,
      params: {chatId}
    });

    if (res?.version == 'whatcrm' && !res.name) return null;
    return res;
  };

  const fetchDialogs = (chat_key: string) =>
    request<AppEnv.Dialog[]>(`v3/instance${chat_key}/dialogs`);

  interface FetchDownloadMedia {
    url: string;
  }

  const fetchDownloadMedia = (
    chat_key: string,
    messageId: string,
    mediaKey: string
  ) =>
    request<FetchDownloadMedia | []>(`v3/instance${chat_key}/downloadMedia`, {
      params: {mediaKey, messageId}
    });

  interface FetchGetNumberId {
    user: string;
  }

  const fetchGetNumberId = (chat_key: string, phone: string) =>
    request<FetchGetNumberId>(`v3/instance${chat_key}/getNumberId`, {
      noNotification: true,
      params: {phone}
    });

  const fetchIntegrationsInstances = (integration_id: number) =>
    request<AppEnv.Instance[]>('v3/integrations/instances', {
      params: {integration_id}
    });

  const fetchIntegrationsOthers = () =>
    request<AppEnv.OtherIntegration[]>('v3/integrations/others');

  const fetchIntegratorsClients = (integration_id: number) =>
    request<AppEnv.IntegratorClient[]>('v3/integrators/clients', {
      params: {integration_id}
    });

  interface FetchIntegratorsGeneration {
    token: string;
  }

  const fetchIntegratorsGeneration = (integration_id: number) =>
    request<FetchIntegratorsGeneration>('v3/integrators/generation', {
      params: {integration_id}
    });

  const fetchIntegratorsIndex = (integration_id: number) =>
    request<AppEnv.Integrator>('v3/integrators/index', {
      params: {integration_id}
    });

  const fetchManagersDelete = (integration_id: number, id: number) =>
    request('v3/managers/delete', {params: {id, integration_id}});

  const fetchManagersList = (integration_id: number) =>
    request<AppEnv.Manager[]>('v3/managers/list', {params: {integration_id}});

  const fetchMe = (chat_key: string) =>
    request<AppEnv.Me>(`v3/instance${chat_key}/me`);

  const fetchMessages = async (
    chat_key: string,
    chatId: number | string,
    version: AppEnv.InstanceVersion,
    fromMessageId: number | undefined,
    maxTimestamp: number | undefined,
    offset: number | undefined,
    noNotification: boolean
  ) => {
    const res = await request<AppEnv.AvitoMessages | AppEnv.Message[]>(
      `v3/instance${chat_key}/messages`,
      {
        noNotification,
        params: {
          chatId,
          fromMessageId: version == 'telegram' ? fromMessageId || 0 : undefined,
          limit: version == 'avito' || version == 'telegram' ? 10 : undefined,
          maxTimestamp: version == 'whatcrm' ? maxTimestamp : undefined,
          offset: version == 'avito' ? offset : undefined
        }
      }
    );

    const a = !Array.isArray(res)
      ? res
      : res.filter(item => typeof item == 'object');

    return a;
  };

  interface FetchPartnersCancel {
    success: 1;
  }

  const fetchPartnersCancel = (integration_id: number) =>
    request<FetchPartnersCancel>('v3/partners/cancel', {
      params: {integration_id}
    });

  const fetchPartnersList = (integration_id: number) =>
    request<AppEnv.Partners>('v3/partners/list', {params: {integration_id}});

  const fetchPayments = (integration_id: number) =>
    request<AppEnv.Payment[]>('v3/payments', {params: {integration_id}});

  interface FetchPinChat {
    result: boolean;
  }

  const fetchPinChat = (chat_key: string, chatId: number | string) =>
    request<FetchPinChat>(`v3/instance${chat_key}/pinChat`, {params: {chatId}});

  const fetchProfileImage = async (
    chat_key: string,
    chatId: number | string
  ) => {
    const res = await request<string>(`v3/instance${chat_key}/profileImage`, {
      noNotification: true,
      params: {chatId}
    });

    if (res?.endsWith('avatar.png')) return null;
    return res;
  };

  interface FetchReadChat {
    _?: 'ok';
    result?: boolean;
  }

  const fetchReadChat = (chat_key: string, chatId: number | string) =>
    request<FetchReadChat>(`v3/instance${chat_key}/readChat`, {
      params: {chatId}
    });

  const fetchRemoteFile = (chat_key: string, remoteFileId: string) =>
    request<AppEnv.TelegramRemoteFile>(`v3/instance${chat_key}/getRemoteFile`, {
      params: {remoteFileId}
    });

  const fetchRequestQrCodeAuthentication = (chat_key: string) =>
    request(`v3/instance${chat_key}/requestQrCodeAuthentication`);

  const fetchSearchPublicChat = (chat_key: string, username: string) =>
    request<AppEnv.TelegramDialogShape>(
      `v3/instance${chat_key}/searchPublicChat`,
      {noNotification: true, params: {username}}
    );

  interface FetchSearchUserByPhoneNumber {
    firstName: string;
    id: number;
    lastName: string;
    username: string;
  }

  const fetchSearchUserByPhoneNumber = (chat_key: string, phone: string) =>
    request<FetchSearchUserByPhoneNumber>(
      `v3/instance${chat_key}/searchUserByPhoneNumber`,
      {noNotification: true, params: {phone}}
    );

  interface FetchSetAuthenticationPhoneNumber {
    code?: string;
  }

  const fetchSetAuthenticationPhoneNumber = (chat_key: string, phone: string) =>
    request<FetchSetAuthenticationPhoneNumber>(
      `v3/instance${chat_key}/setAuthenticationPhoneNumber`,
      {params: {phone}}
    );

  interface FetchStatus extends AppEnv.InstanceCondition {
    state: AppEnv.InstanceStatus;
  }

  const fetchStatus = (chat_key: string, full?: 1) =>
    request<FetchStatus>(`v3/instance${chat_key}/status`, {params: {full}});

  const fetchSubscriptions = async (integration_id: number) => {
    const res = await request<AppEnv.Subscription[]>('v3/subscriptions', {
      params: {integration_id}
    });

    if (!Array.isArray(res)) return null;
    return res;
  };

  const fetchSubscriptionsCancel = (
    integration_id: number,
    subscription_id: string,
    type: AppEnv.SubscriptionTitle
  ) =>
    request('v3/subscriptions/cancel', {
      params: {integration_id, subscription_id, type}
    });

  interface FetchSyncHistory {
    success: boolean;
  }

  const fetchSyncHistory = (chat_key: string, chatId: string) =>
    request<FetchSyncHistory>(`v3/instance${chat_key}/syncHistory`, {
      params: {chatId}
    });

  const fetchTariffs = (
    crm: AppEnv.Crm,
    domain: string,
    currency: 'RUB' | 'USD',
    partner: 1 | undefined
  ) =>
    request<AppEnv.Tariffs>('v3/tariffs', {
      params: {crm, currency, domain, group: 1, partner}
    });

  const fetchTelphinServices = (integration_id: number) =>
    request<AppEnv.TelphinServices>('v3/telphin/services', {
      params: {integration_id}
    });

  interface FetchTemplatesDelete {
    success: boolean;
  }

  const fetchTemplatesDelete = (integration_id: number, id: number) =>
    request<FetchTemplatesDelete>('v3/templates/delete', {
      params: {id, integration_id}
    });

  interface FetchTemplatesFileDelete {
    success: 0 | 1;
  }

  const fetchTemplatesFileDelete = (
    integration_id: number,
    template_id: number,
    id: number
  ) =>
    request<FetchTemplatesFileDelete>('v3/templates/file-delete', {
      params: {id, integration_id, template_id}
    });

  const fetchTemplatesFiles = (integration_id: number, template_id: number) =>
    request<AppEnv.TemplateFile[]>('v3/templates/files', {
      params: {integration_id, template_id}
    });

  interface FetchUnpinChat {
    result: boolean;
  }

  const fetchUnpinChat = (chat_key: string, chatId: number | string) =>
    request<FetchUnpinChat>(`v3/instance${chat_key}/unpinChat`, {
      params: {chatId}
    });

  interface FetchUnreadChat {
    result: boolean;
  }

  const fetchUnreadChat = (chat_key: string, chatId: number | string) =>
    request<FetchUnreadChat>(`v3/instance${chat_key}/unreadChat`, {
      params: {chatId}
    });

  const fetchUser = (
    chat_key: string,
    userId: number,
    noNotification?: boolean
  ) =>
    request<AppEnv.TelegramUser>(`/v3/instance${chat_key}/user`, {
      noNotification,
      params: {userId}
    });

  interface FetchVoiceInfo {
    voice_link: string;
  }

  const fetchVoiceInfo = (chat_key: string, voice_id: string) =>
    request<FetchVoiceInfo>(`v3/instance${chat_key}/voice_info`, {
      params: {voice_id}
    });

  interface FetchWebAuthParams {
    code?: string;
    domain?: string;
    version?: string;
  }

  const fetchWebAuth = (
    email: string,
    {code, domain, version}: FetchWebAuthParams = {}
  ) =>
    request<AppEnv.WebAuth>('v3/web/auth', {
      params: {
        code,
        domain,
        email,
        full_access: domain ? true : undefined,
        version
      }
    });

  const postBlacklistCreate = (
    integration_id: number,
    phone: number | string
  ) =>
    request<AppEnv.Blacklist>('v3/blacklist/create', {
      data: {phone: phone.toString()},
      method: 'post',
      params: {integration_id}
    });

  interface PostChatSpare {
    chat_id: string;
    chat_key: string;
    date_pay: number;
    date_subscription: number;
    date_trial: number;
    id: number;
    is_premium: 0 | 1;
    label: string;
    name: string;
    phone: string;
    status: AppEnv.InstanceStatus;
    tariff_plane: AppEnv.TariffPlan;
    version: AppEnv.InstanceVersion;
    webhook: string | null;
  }

  const postChatSpare = (
    crm: string,
    domain: string,
    version: AppEnv.InstanceVersion,
    label?: string
  ) =>
    request<PostChatSpare>('v3/chat/spare', {
      data: {label},
      method: 'post',
      params: {crm, domain, version}
    });

  interface PostChatUpdateData {
    label?: string;
    name?: string;
    webhook?: string;
    phone?: string;
  }

  interface PostChatUpdate {
    label?: string;
    webhook?: string;
    name?: string;
    phone?: string;
  }

  const postChatUpdate = (id: string, data: PostChatUpdateData) =>
    request<PostChatUpdate>('v3/chat/update', {
      data,
      method: 'post',
      params: {id}
    });

  interface PostEditMessage {
    edit: true;
  }

  const postEditMessage = (
    chat_key: string,
    chatId: number | string,
    messageId: number | string,
    body: string
  ) =>
    request<PostEditMessage | object>(
      `v3/instance${chat_key}/editMessage${
        typeof chatId == 'number' ? 'Text' : ''
      }`,
      {method: 'post', data: {chatId, messageId, body}}
    );

  interface PostEmailPropCode {
    success: number;
  }

  const postEmailPropCode = (code: string, email: string) =>
    request<PostEmailPropCode>('v3/email/prop-code', {
      method: 'post',
      data: {code, email}
    });

  interface PostEmailSendMd5 {
    message: string;
    success: number;
  }

  const postEmailSendMd5 = (email: string) =>
    request<PostEmailSendMd5>('v3/email/send-md5', {
      method: 'post',
      data: {email, generate_code: 1}
    });

  const postFilesLoadData = (blobValue: Blob | File, filename: string) => {
    const {filesUrl} = getBaseUrl();
    const formData = new FormData();

    const uniq = uuid().replaceAll('-', '_');
    const uniqueFilename = `${uniq}_${filename}`;

    formData.append('body', blobValue, uniqueFilename);

    return request<string>('v3/files/load-data', {
      baseURL: filesUrl,
      data: formData,
      isFormData: true,
      method: 'post'
    });
  };

  const postForwardMessage = (
    chat_key: string,
    fromChatId: number | string,
    chatId: string,
    messageId: number | string
  ) =>
    request(`v3/instance${chat_key}/forwardMessage`, {
      data: {chatId, fromChatId, messageId},
      method: 'post',
      noNotification: true
    });

  const postIntegrationsPartner = (
    integration_id: number,
    domain_partner: string
  ) =>
    request<AppEnv.Dashboard>('v3/integrations/partner', {
      params: {integration_id},
      method: 'post',
      data: {domain_partner}
    });

  interface PostIntegrationsUpdate {
    is_manager?: 0 | 1;
    widget_code?: string;
  }

  const postIntegrationsUpdate = (
    integration_id: number,
    data: PostIntegrationsUpdate
  ) =>
    request<Partial<AppEnv.Integration>>(`v3/integrations/update`, {
      data,
      method: 'post',
      params: {integration_id}
    });

  const postIntegratorsUpdate = (
    integration_id: number,
    data: Partial<AppEnv.Integrator>
  ) =>
    request<Partial<AppEnv.Integrator>>('v3/integrators/update', {
      data,
      method: 'post',
      params: {integration_id}
    });

  const postManagersCreate = (
    integration_id: number,
    data: Omit<AppEnv.Manager, 'id' | 'roles'>
  ) =>
    request<AppEnv.Manager>('v3/managers/create', {
      data,
      method: 'post',
      params: {integration_id}
    });

  const postManagersUpdate = (
    integration_id: number,
    id: number,
    data: Partial<Omit<AppEnv.Manager, 'id'>>
  ) =>
    request<AppEnv.Manager>('v3/managers/update', {
      data: {...data, roles: JSON.stringify(data.roles)},
      method: 'post',
      params: {id, integration_id}
    });

  interface PostPartnersData {
    crm: AppEnv.Crm;
    domain: string;
  }

  interface PostPartnersAdd {
    client: AppEnv.Partner;
  }

  const postPartnersAdd = (integration_id: number, data: PostPartnersData) =>
    request<PostPartnersAdd>('v3/partners/add', {
      data,
      method: 'post',
      params: {integration_id}
    });

  interface PostPartnersDelete {
    success: 1;
  }

  const postPartnersDelete = (integration_id: number, data: PostPartnersData) =>
    request<PostPartnersDelete>('v3/partners/delete', {
      data,
      method: 'post',
      params: {integration_id}
    });

  const postReaction = (
    chat_key: string,
    chatId: number | string,
    messageId: number | string,
    reaction: string
  ) =>
    request(`v3/instance${chat_key}/reaction`, {
      method: 'post',
      data: {chatId, messageId, reaction}
    });

  interface PostRemoveChat {
    _?: 'ok';
    result?: boolean;
  }

  const postRemoveChat = (chat_key: string, chatId: number | string) =>
    request<PostRemoveChat>(`v3/instance${chat_key}/removeChat`, {
      data: {chatId},
      method: 'post'
    });

  interface PostSendMessage {
    message_id?: string;
    sent?: boolean;
  }

  interface PostSendFileParams {
    caption?: string;
    chatId?: number | string;
    duration?: number;
    filename?: string;
    quotedMsgId?: number | string;
    username?: string;
    waveform?: string;
  }

  const postSendFile = (
    chat_key: string,
    body: string,
    params?: PostSendFileParams
  ) =>
    request<PostSendMessage>(`v3/instance${chat_key}/sendFile`, {
      data: {...params, body},
      method: 'post'
    });

  interface Button {
    body: string;
  }

  interface PostSendMessageParams {
    allowMultipleAnswers?: boolean;
    buttons?: Button[];
    chatId?: number | string;
    quotedMsgId?: number | string;
    username?: string;
  }

  const postSendMessage = (
    chat_key: string,
    body: string,
    params: PostSendMessageParams
  ) =>
    request<PostSendMessage | AppEnv.AvitoMessage>(
      `v3/instance${chat_key}/sendMessage`,
      {data: {...params, body}, method: 'post'}
    );

  interface PostTemplatesData {
    title: string;
    message: string;
  }

  const postTemplatesCreate = (
    integration_id: number,
    {message, title}: PostTemplatesData
  ) =>
    request<AppEnv.Template>('v3/templates/create', {
      data: {message, title},
      method: 'post',
      params: {integration_id}
    });

  interface PostTemplatesFileAddData {
    link: string;
    title: string;
    type: string;
  }

  const postTemplatesFileAdd = (
    integration_id: number,
    template_id: number,
    {link, title, type}: PostTemplatesFileAddData
  ) =>
    request<AppEnv.TemplateFile>('v3/templates/file-add', {
      data: {link, title, type},
      method: 'post',
      params: {integration_id, template_id}
    });

  const postTemplatesUpdate = (
    integration_id: number,
    id: number,
    {message, title}: PostTemplatesData
  ) =>
    request<AppEnv.Template>('v3/templates/update', {
      params: {id, integration_id},
      method: 'post',
      data: {message, title}
    });

  return {
    fetchBlacklistDelete,
    fetchChatFree,
    fetchChatInfo,
    fetchChatRead,
    fetchCheckAuthenticationCode,
    fetchCheckAuthenticationPassword,
    fetchCreatePrivateChat,
    fetchDashboard,
    fetchDeleteMessage,
    fetchDeleteMessageAvito,
    fetchDestroy,
    fetchDialog,
    fetchDialogs,
    fetchDownloadMedia,
    fetchGetNumberId,
    fetchIntegrationsInstances,
    fetchIntegrationsOthers,
    fetchIntegratorsClients,
    fetchIntegratorsGeneration,
    fetchIntegratorsIndex,
    fetchManagersDelete,
    fetchManagersList,
    fetchMe,
    fetchMessages,
    fetchPartnersCancel,
    fetchPartnersList,
    fetchPayments,
    fetchPinChat,
    fetchProfileImage,
    fetchReadChat,
    fetchRemoteFile,
    fetchRequestQrCodeAuthentication,
    fetchSearchPublicChat,
    fetchSearchUserByPhoneNumber,
    fetchSetAuthenticationPhoneNumber,
    fetchStatus,
    fetchSubscriptions,
    fetchSubscriptionsCancel,
    fetchSyncHistory,
    fetchTariffs,
    fetchTelphinServices,
    fetchTemplatesDelete,
    fetchTemplatesFileDelete,
    fetchTemplatesFiles,
    fetchUnpinChat,
    fetchUnreadChat,
    fetchUser,
    fetchVoiceInfo,
    fetchWebAuth,
    postBlacklistCreate,
    postChatSpare,
    postChatUpdate,
    postEditMessage,
    postEmailPropCode,
    postEmailSendMd5,
    postFilesLoadData,
    postForwardMessage,
    postIntegrationsPartner,
    postIntegrationsUpdate,
    postIntegratorsUpdate,
    postManagersCreate,
    postManagersUpdate,
    postPartnersAdd,
    postPartnersDelete,
    postReaction,
    postRemoveChat,
    postSendFile,
    postSendMessage,
    postTemplatesCreate,
    postTemplatesFileAdd,
    postTemplatesUpdate
  };
};

export default useRequest;
