import React from 'react';

import {Text} from 'components';
import {useInstanceContext} from '../../instance-context';

const Id = () => {
  const {instance} = useInstanceContext();

  return (
    <Text>
      <Text color="wild" isSpan>
        {'ID:'}
      </Text>{' '}
      {instance.id}
    </Text>
  );
};

export default Id;
