import React from 'react';
import * as AppEnv from 'app-env';

import AuthContext from './auth-context';
import Main from './main/main';
import Side from './side/side';

interface Auth {
  setUser: AppEnv.SetState<AppEnv.User | null | undefined>;
}

const Auth = ({setUser}: Auth) => (
  <AuthContext.Provider value={{setUser}}>
    <div className="auth">
      <div className="auth__wrapper">
        <Side />
        <Main />
      </div>
    </div>
  </AuthContext.Provider>
);

export default Auth;
