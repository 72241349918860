import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Body,
  Nav,
  Page,
  PageBody,
  PageMain,
  Placeholder,
  SaveButton,
  Wrapper
} from 'components';
import Url from './url/url';

const validationSchema = yup
  .object()
  .shape({url: yup.string().trim().required().min(4)});

const ShortUrls = () => {
  const {t} = useTranslation();

  const options = [{label: t`Short URLs`, value: '/short-urls'}];

  return (
    <Body>
      <Page>
        <Nav options={options} value="/short-urls" />

        <PageBody>
          <PageMain>
            <Formik
              initialValues={{url: ''}}
              onSubmit={() => {}}
              validateOnBlur={false}
              validationSchema={validationSchema}
            >
              <Form>
                <Wrapper flexWrap="resp-wrap" gap={16}>
                  <Url />

                  <SaveButton minHeight={40} style={{flexGrow: 1}}>
                    {t`Shorten`}
                  </SaveButton>
                </Wrapper>
              </Form>
            </Formik>

            <Placeholder isMaxHeight title={t`No short URLs`} />
          </PageMain>
        </PageBody>
      </Page>
    </Body>
  );
};

export default ShortUrls;
