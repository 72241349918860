import {AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import useInstance from './use-instance/use-instance';

interface Params {
  data?: object;
  method?: 'post';
  params?: object;
}

const useRequestBitrix24 = (integration: AppEnv.Integration | undefined) => {
  const {setNotification} = useAppContext();

  const {t} = useTranslation();
  const instance = useInstance(integration);

  // eslint-disable-next-line
  const throwError = (data: any) => {
    const text = data?.detail || t`Something went wrong.`;
    setNotification({title: t`Error`, text});

    return null;
  };

  const handleThen = <T>(res: AxiosResponse): T | null => {
    const {data} = res;

    if ('detail' in data) return throwError(data);
    else if ('error' in data) return throwError(data);
    else if ('success' in data && !data.success) return throwError(data);

    return data;
  };

  // eslint-disable-next-line
  const handleCatch = (e: any) => {
    const text = e.response.data?.detail || t`Something went wrong.`;
    setNotification({title: t`Error`, text});

    return null;
  };

  const request = async <T>(
    path: string,
    {data, method, params}: Params
  ): Promise<T | null> => {
    if (!instance) return throwError(undefined);

    const res = await instance(path, {data, method, params})
      .then(handleThen<T>)
      .catch(handleCatch);

    return res;
  };

  const fetchPrefs = (domain: string) =>
    request(`v1/prefs/${domain}`, {params: {t: 'update'}});

  const fetchVoiceMessage = (key: string, src: string) =>
    request('v1/voice_message', {data: {src}, method: 'post', params: {key}});

  return {fetchPrefs, fetchVoiceMessage};
};

export default useRequestBitrix24;
