import React from 'react';
import {Accept, useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Icons, Text, Wrapper} from 'components';

interface DropzoneAlt {
  accept?: Accept;
  multiple?: boolean;
  onChange: (files: File[]) => void;
  placeholder?: string;
  style?: React.CSSProperties;
}

const DropzoneAlt = ({
  accept,
  multiple,
  onChange,
  placeholder,
  style
}: DropzoneAlt) => {
  const {getInputProps, getRootProps, isDragAccept} = useDropzone({
    accept,
    multiple,
    onDrop: onChange
  });

  const {t} = useTranslation();

  const htmlDragPlaceholder = multiple ? t`Drop files here` : t`Drop file here`;
  const htmlPlaceholder = placeholder || (multiple ? t`Files` : t`File`);

  return (
    <div
      {...getRootProps()}
      className={cn('dropzone-alt', {'dropzone-alt_active': isDragAccept})}
      style={style}
    >
      <input {...getInputProps()} />

      <Wrapper flexWrap="nowrap" gap={4}>
        <Text color="blue" size={16}>
          {isDragAccept ? htmlDragPlaceholder : htmlPlaceholder}
        </Text>

        <Icons.PaperClip color="blue" size={20} />
      </Wrapper>
    </div>
  );
};

export default DropzoneAlt;
