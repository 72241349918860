import {formatPhoneNumber} from 'whatcrm-core';

import {getChatDialogName} from '..';
import * as AppEnv from 'app-env';

const getDialogName = (instance: AppEnv.Instance, dialog: AppEnv.Dialog) => {
  let name: string | undefined = undefined;

  if (dialog.version == 'avito') {
    const {me} = instance;
    const user = dialog.users.find(item => item.id != me?.id);

    name = user?.name;
  } else if (dialog.version == 'chat') {
    return getChatDialogName(dialog);
  } else if (dialog.version == 'telegram' || dialog.version == 'whatcrm') {
    name = dialog.name;
  }

  if (!name) return null;
  return formatPhoneNumber(name);
};

export default getDialogName;
