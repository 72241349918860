import React from 'react';
import cn from 'classnames';

interface TableRow {
  children: React.ReactNode;
  isActive?: boolean;
}

const TableRow = ({children, isActive}: TableRow) => (
  <tr className={cn('table__row', {table__row_active: isActive})}>
    {children}
  </tr>
);

export default TableRow;
