import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Checkbox,
  PageBody,
  PageFooter,
  PageMain,
  Wrapper
} from 'components';
import {useTariffsContext} from '../tariffs-context';
import * as AppEnv from 'app-env';

import {PaymentGateway as IPaymentGateway, Stage} from './tariff-list-env';
import {useSumma} from './hooks';
import ConnectionSelect from './connection-select/connection-select';
import LicenseAgreement from './license-agreement/license-agreement';
import Location from './location/location';
import PaymentGateway from './payment-gateway/payment-gateway';
import Tariff from './tariff/tariff';
import TariffListContext from './tariff-list-context';

interface TariffList {
  tariffs: AppEnv.Tariffs;
}

const TariffList = ({tariffs}: TariffList) => {
  const {isWrite} = useTariffsContext();

  const [location, setLocation] = useState<string>();
  const [instanceId, setInstanceId] = useState<number[]>([]);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [paymentGateway, setPaymentGateway] = useState<IPaymentGateway>();
  const [stage, setStage] = useState<Stage>();

  const {t} = useTranslation();

  useEffect(() => {
    if (stage) return;

    setLocation(undefined);
    setInstanceId([]);
    setPaymentGateway(undefined);
  }, [stage]);

  const tariffOptions: AppEnv.Tariff[] = Object.values(
    tariffs.Pro || tariffs.Start
  );

  const initialSelectedTariff = tariffOptions.find(
    tariff => tariff.quantity == 3
  );

  const [selectedTariff, setSelectedTariff] = useState(initialSelectedTariff);

  const summa = useSumma(selectedTariff);
  const total = parseFloat(summa || '0') * instanceId.length;

  return (
    <TariffListContext.Provider
      value={{selectedTariff, setSelectedTariff, total}}
    >
      <PageBody>
        <PageMain>
          <div className="tariffs">
            <Tariff
              tariffOptions={Object.values(tariffs.Start)}
              tariffPlan="Start"
              tariffs={tariffs}
            />

            {tariffs.Pro && (
              <Tariff
                tariffOptions={Object.values(tariffs.Pro)}
                tariffPlan="Pro"
                tariffs={tariffs}
              />
            )}

            {tariffs.Enterprise && (
              <Tariff
                tariffOptions={Object.values(tariffs.Enterprise)}
                tariffPlan="Enterprise"
                tariffs={tariffs}
              />
            )}
          </div>
        </PageMain>
      </PageBody>

      {isWrite && (
        <PageFooter>
          <Wrapper gap={16}>
            <Button
              onClick={() => setStage('connection-select')}
              state={selectedTariff ? undefined : 'disabled'}
            >
              {t`Continue`}
            </Button>

            <Checkbox
              label={t`Subscribe`}
              name="is_subscribed"
              value={isSubscribed}
              onChange={setIsSubscribed}
            />
          </Wrapper>
        </PageFooter>
      )}

      <ConnectionSelect
        instanceId={instanceId}
        stage={stage}
        setInstanceId={setInstanceId}
        setStage={setStage}
      />

      {paymentGateway && (
        <LicenseAgreement
          instanceId={instanceId}
          isSubscribed={isSubscribed}
          location={location}
          paymentGateway={paymentGateway}
          setStage={setStage}
          stage={stage}
        />
      )}

      <Location
        location={location}
        stage={stage}
        setLocation={setLocation}
        setStage={setStage}
      />

      <PaymentGateway
        paymentGateway={paymentGateway}
        stage={stage}
        setPaymentGateway={setPaymentGateway}
        setStage={setStage}
      />
    </TariffListContext.Provider>
  );
};

export default TariffList;
