import {getDialogId} from 'common/actions';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useMenuContext} from '../../menu-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useMessageContext} from '../../../../../message-context';
import {useRequest} from 'common/hooks';

const useSendReaction = () => {
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();
  const {message} = useMessageContext();
  const {setIsContextMenuActive} = useMenuContext();

  const {postReaction} = useRequest();

  const sendReaction = (reaction: string) => {
    setIsContextMenuActive(false);

    const dialogId = getDialogId(dialog, true);
    if (!dialogId) return;

    postReaction(instance.chat_key, dialogId, message.id, reaction);
  };

  return sendReaction;
};

export default useSendReaction;
