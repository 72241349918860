import React, {useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {Body} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Aside from './aside/aside';
import WidgetContext from './widget-context';
import Window from './window/window';

const validationSchema = yup
  .object()
  .shape({instance_chat_key: yup.string().required()});

const Widget = () => {
  const {activeInstances} = useWorkspaceContext();
  const [isCodeActive, setIsCodeActive] = useState(false);

  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Widget`} - Whatcrm Web`;
  }, []);

  const instance = activeInstances.find(instance => instance.version == 'chat');

  const initialValues: AppEnv.Widget = {
    bottom_mobile: null,
    bottom: null,
    callback_button_text: '',
    callback_field_type: 'nonlinear',
    callback_header: '',
    callback_is_message: true,
    callback_type: 'horizontal',
    chat_message_type: 'minimalism',
    chat_type: 'max',
    colors: {primary: '#2f59cc', secondary: '#ffffff'},
    icon: 'faq-chat',
    instance_chat_key: instance?.chat_key || null,
    is_callback: true,
    is_chat: true,
    is_pulse: true,
    right_mobile: null,
    right: null,
    socials: [],
    theme: 'light',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    type: 'radial',
    unavailable_text: '',
    working_hours: [
      {end: '', isWorking: false, start: ''},
      {end: '', isWorking: true, start: ''},
      {end: '', isWorking: true, start: ''},
      {end: '', isWorking: true, start: ''},
      {end: '', isWorking: true, start: ''},
      {end: '', isWorking: true, start: ''},
      {end: '', isWorking: false, start: ''}
    ]
  };

  return (
    <WidgetContext.Provider value={{isCodeActive, setIsCodeActive}}>
      <Body>
        <div className="widget">
          <Formik
            initialValues={initialValues}
            onSubmit={() => setIsCodeActive(true)}
            validateOnBlur={false}
            validationSchema={validationSchema}
          >
            <Form>
              <Window />
              <Aside />
            </Form>
          </Formik>
        </div>
      </Body>
    </WidgetContext.Provider>
  );
};

export default Widget;
