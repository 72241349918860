import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import * as AppEnv from 'app-env';

import useClear from './use-clear/use-clear';

const useOptions = (
  setIsDeleteDialogActive: AppEnv.SetState<boolean>,
  setIsProfileActive: AppEnv.SetState<boolean>,
  setIsWallpapersActive: AppEnv.SetState<boolean>
): ContextMenuOption[] => {
  const {t} = useTranslation();
  const clear = useClear(setIsDeleteDialogActive);

  const info = {
    icon: <IconsAlt.Question />,
    label: t`Information`,
    onClick: () => setIsProfileActive(true)
  };

  const wallpaper = {
    icon: <IconsAlt.Brush />,
    label: t`Change the wallpaper`,
    onClick: () => setIsWallpapersActive(true)
  };

  const options = [info, wallpaper, clear].filter(option => option != null);
  return options as ContextMenuOption[];
};

export default useOptions;
