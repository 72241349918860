import upd from 'immutability-helper';

import {useManagerContext} from '../../manager-context';
import {useAccessContext} from '../../../access-context';
import * as AppEnv from 'app-env';

const useUpdManagerRoles = () => {
  const {setManagerList} = useAccessContext();
  const {manager} = useManagerContext();

  const updManagerRoles = (roles: AppEnv.ManagerRoles) =>
    setManagerList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == manager.id);
      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {[index]: {roles: {$set: roles}}});
    });

  return updManagerRoles;
};

export default useUpdManagerRoles;
