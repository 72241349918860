import React from 'react';
import {useTranslation} from 'react-i18next';

import {Table, TableBody, TableData, TableHead} from 'components';
import * as AppEnv from 'app-env';

import Bonus from './bonus/bonus';

interface BonusList {
  bonusList: AppEnv.TelphinBonus[];
}

const BonusList = ({bonusList}: BonusList) => {
  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableData>{t`Service`}</TableData>
        <TableData>{t`Cost`}</TableData>
        <TableData>{t`Status`}</TableData>
      </TableHead>

      <TableBody>
        {bonusList.map(bonus => (
          <Bonus bonus={bonus} key={bonus.bonus_id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default BonusList;
