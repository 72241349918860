import axios from 'axios';
import * as AppEnv from 'app-env';

const getBaseURL = (integration: AppEnv.Integration | undefined) => {
  if (integration?.type == 'MONDAY') return 'https://monwa.whatcrm.net';
  else if (integration?.type == 'TGMONDAY') return 'https://montg.whatcrm.net';
};

const useInstance = (integration: AppEnv.Integration | undefined) => {
  const baseURL = getBaseURL(integration);
  if (!baseURL) return null;

  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'X-Monday-Token': '35b6e76e7407cbc0544b4092d7492ef9'
    }
  });

  return instance;
};

export default useInstance;
