import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {MultiSelect, SelectOption} from 'components';
import {useManagerContext} from '../manager-context';
import {useRequest} from 'common/hooks';
import {useUpdManagerRoles} from '../hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

type Value =
  | 'delete'
  | 'edit'
  | 'file'
  | 'forward'
  | 'polls'
  | 'reactions'
  | 'reply'
  | 'voice'
  | 'write';

interface Messages {
  getLabel: (str: string) => string;
}

const Messages = ({getLabel}: Messages) => {
  const {user} = useWorkspaceContext();
  const {manager} = useManagerContext();

  const {postManagersUpdate} = useRequest();
  const {t} = useTranslation();
  const updManagerRoles = useUpdManagerRoles();

  const handleChange = async (value: Value[]) => {
    const {integration} = user;

    const newValue: AppEnv.ManagerRolesMessages = {
      audio: true,
      delete: value.includes('delete'),
      edit: value.includes('edit'),
      file: value.includes('file'),
      forward: value.includes('forward'),
      photo: true,
      polls: value.includes('polls'),
      reactions: value.includes('reactions'),
      read: true,
      reply: value.includes('reply'),
      stories: true,
      video: true,
      voice: value.includes('voice'),
      write: value.includes('write')
    };

    const newRoles = upd(manager.roles, {messages: {$set: newValue}});

    updManagerRoles(newRoles);

    const res = await postManagersUpdate(integration.id, manager.id, {
      roles: newRoles
    });

    if (res) return;

    updManagerRoles(manager.roles);
  };

  const {roles} = manager;

  const options: SelectOption<Value>[] = [
    {label: t`Reactions`, value: 'reactions'},
    {label: t`Reply`, value: 'reply'},
    {label: t`Edit`, value: 'edit'},
    {label: t`Forward`, value: 'forward'},
    {label: t`Delete`, value: 'delete'},
    {label: t`Text messages`, value: 'write'},
    {label: t`Files`, value: 'file'},
    {label: t`Polls`, value: 'polls'},
    {label: t`Voice messages`, value: 'voice'}
  ];

  const value = Object.entries(roles.messages).reduce((acc, [key, value]) => {
    if (
      (key == 'delete' ||
        key == 'edit' ||
        key == 'file' ||
        key == 'forward' ||
        key == 'polls' ||
        key == 'reactions' ||
        key == 'reply' ||
        key == 'voice' ||
        key == 'write') &&
      value
    )
      acc.push(key);

    return acc;
  }, [] as Value[]);

  return (
    <MultiSelect
      isAlt
      maxWidth={160}
      onChange={handleChange}
      optgroup={getLabel(t`Messages`)}
      options={options}
      placeholder={t`Read-only`}
      value={value}
    />
  );
};

export default Messages;
