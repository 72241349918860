import React from 'react';

interface OneTimePassword {
  children: React.ReactNode;
}

const OneTimePassword = ({children}: OneTimePassword) => (
  <p className="one-time-password">{children}</p>
);

export default OneTimePassword;
