import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useClear = (
  dialog: AppEnv.Dialog,
  setIsConfirmationActive: AppEnv.SetState<boolean>
): ContextMenuOption | null => {
  const {user} = useWorkspaceContext();

  const {t} = useTranslation();

  const {manager} = user;
  const {dialogs} = manager.roles;

  if (dialog.version != 'telegram' && dialog.version != 'whatcrm') return null;
  else if (!dialogs.delete) return null;

  const clear = {
    icon: <IconsAlt.TrashSimple />,
    label: dialog.version == 'telegram' ? t`Delete` : t`Clear`,
    onClick: () => setIsConfirmationActive(true)
  };

  return clear;
};

export default useClear;
