import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {removeFromLocalStorage} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Confirm, Icons, Text} from 'components';
import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import MenuItem from './menu-item/menu-item';

interface Menu {
  setIsSidebarActive: AppEnv.SetState<boolean>;
}

const Menu = ({setIsSidebarActive}: Menu) => {
  const {isIframe} = useAppContext();
  const {setInstanceReferer, user} = useWorkspaceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);

  const {t} = useTranslation();
  const location = useLocation();

  const handleClick = () => {
    if (window.innerWidth <= 768) setIsSidebarActive(false);
    if (!location.pathname.startsWith('/messenger/')) return;

    const instanceId = location.pathname.split('/')[2];
    setInstanceReferer(instanceId);
  };

  const signOut = () => {
    removeFromLocalStorage('email');
    window.location.href = '/';
  };

  const handleConfirm = (res: boolean) =>
    res ? signOut() : setIsConfirmActive(false);

  const {integration, manager} = user;

  const {
    billing,
    blacklist,
    instances,
    integrators,
    managers,
    partners,
    products,
    templates
  } = manager.roles;

  return (
    <>
      <div className="sidebar__menu">
        <>
          {process.env.REACT_APP_ENV == 'dev' && (
            <>
              {!isIframe && (
                <>
                  {instances.read && (
                    <Link replace={isIframe} to="/connections">
                      <MenuItem
                        isActive={location.pathname.startsWith('/connections')}
                        onClick={handleClick}
                      >
                        <Icons.WhatsAppLogo size={20} />
                        <Text size={16}>{t`Connections`}</Text>
                      </MenuItem>
                    </Link>
                  )}
                </>
              )}
            </>
          )}
        </>

        {process.env.REACT_APP_ENV == 'dev' && (
          <>
            {managers.read && (
              <Link replace={isIframe} to="/staff">
                <MenuItem
                  isActive={location.pathname.startsWith('/staff')}
                  onClick={handleClick}
                >
                  <Icons.Users size={20} />
                  <Text size={16}>{t`Staff`}</Text>
                </MenuItem>
              </Link>
            )}
          </>
        )}

        {integration.is_partner > 0 &&
          partners.read &&
          process.env.REACT_APP_ENV == 'dev' && (
            <Link replace={isIframe} to="/partner-program">
              <MenuItem
                isActive={location.pathname.startsWith('/partner-program')}
                onClick={handleClick}
              >
                <Icons.Handshake size={20} />
                <Text size={16}>{t`Partner program`}</Text>
              </MenuItem>
            </Link>
          )}

        {process.env.REACT_APP_ENV == 'dev' &&
          integration.is_integration > 0 &&
          integrators.read && (
            <Link replace={isIframe} to="/integration">
              <MenuItem
                isActive={location.pathname.startsWith('/integration')}
                onClick={handleClick}
              >
                <Icons.GitMerge size={20} />
                <Text size={16}>{t`Integration`}</Text>
              </MenuItem>
            </Link>
          )}

        {process.env.REACT_APP_ENV == 'dev' && billing.read && (
          <Link replace={isIframe} to="/billing">
            <MenuItem
              isActive={location.pathname.startsWith('/billing')}
              onClick={handleClick}
            >
              <Icons.Receipt size={20} />
              <Text size={16}>{t`Billing`}</Text>
            </MenuItem>
          </Link>
        )}

        {templates.read && (
          <Link replace={isIframe} to="/templates">
            <MenuItem
              isActive={location.pathname.startsWith('/templates')}
              onClick={handleClick}
            >
              <Icons.Note size={20} />
              <Text size={16}>{t`Templates`}</Text>
            </MenuItem>
          </Link>
        )}

        {blacklist.read && (
          <Link replace={isIframe} to="/blocked">
            <MenuItem
              isActive={location.pathname.startsWith('/blocked')}
              onClick={handleClick}
            >
              <Icons.FolderSimpleLock size={20} />
              <Text size={16}>{t`Blocked chats`}</Text>
            </MenuItem>
          </Link>
        )}

        {process.env.REACT_APP_ENV == 'dev' && (
          <>
            <Link replace={isIframe} to="/short-urls">
              <MenuItem
                isActive={location.pathname.startsWith('/short-urls')}
                onClick={handleClick}
              >
                <Icons.Link size={20} />
                <Text size={16}>{t`Short URLs`}</Text>
              </MenuItem>
            </Link>
          </>
        )}

        {process.env.REACT_APP_ENV == 'dev' && (
          <>
            {!isIframe && (
              <Link replace={isIframe} to="/widget">
                <MenuItem
                  isActive={location.pathname.startsWith('/widget')}
                  onClick={handleClick}
                >
                  <Icons.CirclesThree size={20} />
                  <Text size={16}>{t`Widget`}</Text>
                </MenuItem>
              </Link>
            )}
          </>
        )}

        {process.env.REACT_APP_ENV == 'dev' && (
          <>
            {!isIframe && products.read && (
              <Link replace={isIframe} to="/products">
                <MenuItem
                  isActive={location.pathname.startsWith('/products')}
                  onClick={handleClick}
                >
                  <Icons.Bag size={20} />
                  <Text size={16}>{t`Products`}</Text>
                </MenuItem>
              </Link>
            )}
          </>
        )}

        <Link replace={isIframe} to="/settings">
          <MenuItem
            isActive={location.pathname.startsWith('/settings')}
            onClick={handleClick}
          >
            <Icons.GearSettings size={20} />
            <Text size={16}>{t`Settings`}</Text>
          </MenuItem>
        </Link>

        {!isIframe && (
          <MenuItem onClick={() => setIsConfirmActive(true)}>
            <Icons.SignOut size={20} />
            <Text size={16}>{t`Sign out`}</Text>
          </MenuItem>
        )}
      </div>

      <Confirm
        title={t`Are you sure you want to sign out?`}
        isActive={isConfirmActive}
        onClick={handleConfirm}
      />
    </>
  );
};

export default Menu;
