import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface MenuContext {
  setIsContextMenuActive: AppEnv.SetState<boolean>;
}

const MenuContext = React.createContext<MenuContext>({} as MenuContext);

export const useMenuContext = () => useContext(MenuContext);
export default MenuContext;
