import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import {getIsDialogUnread} from 'common/actions';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import useToggleRead from './use-toggle-read/use-toggle-read';

const useRead = (dialog: AppEnv.Dialog): ContextMenuOption | null => {
  const {user} = useWorkspaceContext();

  const {t} = useTranslation();
  const toggleRead = useToggleRead(dialog);

  const {manager} = user;
  const {dialogs} = manager.roles;
  if (!dialogs.read) return null;

  const isUnread = getIsDialogUnread(dialog);

  const read = {
    icon: <IconsAlt.CheckSquareOffset />,
    label: t`Mark as read`,
    onClick: () => toggleRead(true)
  };

  const unread = {
    icon: <IconsAlt.Notification />,
    label: t`Mark as unread`,
    onClick: () => toggleRead(false)
  };

  if (isUnread) return read;
  else if (dialog.version == 'telegram' || dialog.version == 'whatcrm')
    return unread;

  return null;
};

export default useRead;
