import {useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useGetMessagePreview} from 'common/hooks';
import * as AppEnv from 'app-env';

const useCopyMsg = (msg: AppEnv.Message) => {
  const {setNotification} = useAppContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();
  const getMessagePreview = useGetMessagePreview();

  const messagePreview = getMessagePreview(msg);

  const copyMessage = async () => {
    if (!messagePreview)
      return setNotification({title: t`Failed to copy the message`});

    const res = await copyToClipboard(messagePreview);

    setNotification({
      title: res.success
        ? t`The message has been copied to clipboard`
        : res.message
    });
  };

  return copyMessage;
};

export default useCopyMsg;
