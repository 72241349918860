import React from 'react';
import {useField} from 'formik';

import {Button, Icons} from 'components';

const Clear = () => {
  const [, , helpers] = useField<string>('text');

  return (
    <Button color="transparent" onClick={() => helpers.setValue('')}>
      <Icons.CircleClose />
    </Button>
  );
};

export default Clear;
