import axios from 'axios';

import {getUrlParams} from 'common/actions';
import * as AppEnv from 'app-env';

const getBaseURL = (integration: AppEnv.Integration | undefined) => {
  const {ref} = getUrlParams();

  if (ref) return `https://${ref}`;
  else if (integration?.type == 'B24') return 'https://bitrix24.whatcrm.net';
  else if (integration?.type == 'TELPHGRAM')
    return 'https://telphgram.whatcrm.net';
};

const useInstance = (integration: AppEnv.Integration | undefined) => {
  const baseURL = getBaseURL(integration);
  if (!baseURL) return null;

  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Token': 'b3e18c7fcaa2ff6a4ec2b6f55dd29399'
    }
  });

  return instance;
};

export default useInstance;
