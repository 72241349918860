import {AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import useInstance from './use-instance/use-instance';

interface Params {
  data?: object;
  method?: 'post';
  params?: object;
}

const useRequestPipedrive = (integration: AppEnv.Integration | undefined) => {
  const {setNotification} = useAppContext();

  const {t} = useTranslation();
  const instance = useInstance(integration);

  // eslint-disable-next-line
  const throwError = (data: any) => {
    const text = data?.message || t`Something went wrong.`;
    setNotification({title: t`Error`, text});

    return null;
  };

  const handleThen = <T>(res: AxiosResponse): T | null => {
    const {data} = res;
    if ('success' in data && !data.success) return throwError(data);

    return data;
  };

  // eslint-disable-next-line
  const handleCatch = (e: any) => {
    const {data} = e?.response || {};
    return throwError(data);
  };

  const request = async <T>(
    path: string,
    {data, method, params}: Params
  ): Promise<T | null> => {
    if (!instance) return throwError(undefined);

    const res = await instance(path, {data, method, params})
      .then(handleThen<T>)
      .catch(handleCatch);

    return res;
  };

  const fetchEmployees = (domain: string) =>
    request('api/employees', {params: {domain}});

  return {fetchEmployees};
};

export default useRequestPipedrive;
