import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {Confirm} from 'components';
import {getDialogId} from 'common/actions';
import {Item} from '../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import {useItems} from './hooks';

const List = () => {
  const {isIframe, setNotification} = useAppContext();
  const {setUser, user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);

  const {postBlacklistCreate} = useRequest();
  const {t} = useTranslation();
  const items = useItems(setIsConfirmActive);
  const navigate = useNavigate();

  const blockUser = async () => {
    const {integration} = user;

    const id = getDialogId(dialog);
    if (!id) return true;

    const res = await postBlacklistCreate(integration.id, id);
    if (!res) return true;

    setUser(prevValue => update(prevValue, {blacklist: {$push: [res]}}));
    setNotification({title: t`The user has been blocked`});
    navigate(`/messenger/${instance.id}`, {replace: isIframe});

    return true;
  };

  const handleConfirm = (res: boolean) => {
    if (res) return blockUser();
    setIsConfirmActive(false);
  };

  return (
    <>
      {items.length > 0 && (
        <div className="profile__list">
          {items.map((item, i) => (
            <Item {...item} key={i} />
          ))}
        </div>
      )}

      <Confirm
        isActive={isConfirmActive}
        onClick={handleConfirm}
        title={t`Are you sure you want to block this user?`}
      />
    </>
  );
};

export default List;
