import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {SingleSelectAlt, Title} from 'components';

const values = [
  'faq-chat',
  'help-center',
  'live-chat',
  'support-24',
  'virtual-assistant'
];

const options = values.map(value => ({
  icon: `/static/widget/icons.svg#${value}`,
  value
}));

const Type = () => {
  const [field, , helpers] = useField<string>('icon');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Icon`}</Title.H3>

      <SingleSelectAlt
        {...field}
        options={options}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Type;
