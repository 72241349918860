import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import AccessInstances from './access-instances/access-instances';
import Email from './email/email';
import Fullname from './fullname/fullname';
import Phone from './phone/phone';

interface ManagerForm {
  isActive: boolean;
  manager?: AppEnv.Manager;
  onSubmit: (values: Omit<AppEnv.Manager, 'id' | 'roles'>) => Promise<boolean>;
  setIsActive: AppEnv.SetState<boolean>;
}

const initialValues = {
  access_instances: '',
  email: '',
  fullname: '',
  phone: ''
};

const validationSchema = yup.object().shape({
  email: yup.string().trim().required().min(6),
  fullname: yup.string().trim().required()
});

const ManagerForm = ({
  isActive,
  manager,
  onSubmit,
  setIsActive
}: ManagerForm) => {
  const {user} = useWorkspaceContext();
  const {t} = useTranslation();
  const {integration} = user;

  return (
    <Popup isActive={isActive} onClick={() => setIsActive(false)} width={408}>
      <Formik
        initialValues={manager || initialValues}
        onSubmit={onSubmit}
        validateOnMount={false}
        validationSchema={validationSchema}
      >
        <Form>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>
              {manager ? t`Edit staff member` : t`Add staff member`}
            </Title.H3>

            {integration.type == 'LK' && (
              <>
                <Fullname />
                <Email />
                <Phone />
              </>
            )}

            <AccessInstances />
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Save`}</SaveButton>

              <Button color="fade" onClick={() => setIsActive(false)}>
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default ManagerForm;
