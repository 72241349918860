import React, {useState, useEffect} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {AudioPlayer, Icons, SaveButton, Wrapper} from 'components';
import {normalizeWaveform} from 'common/actions';
import * as AppEnv from 'app-env';

interface Preview {
  setIsVoiceRecording: AppEnv.SetState<boolean>;
}

const Preview = ({setIsVoiceRecording}: Preview) => {
  const [base64, setBase64] = useState<string>();

  const [field, , helpers] = useField<AppEnv.Voice | undefined>('voice');
  const {t} = useTranslation();

  useEffect(() => {
    if (!field.value) return;
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      if (typeof fileReader.result == 'string') setBase64(fileReader.result);
    };

    fileReader.readAsDataURL(field.value.blob);
  }, []);

  const handleClick = () => {
    helpers.setValue(undefined);
    setIsVoiceRecording(false);
  };

  let waveform = field.value?.waveform
    ? normalizeWaveform(field.value?.waveform)
    : undefined;

  if (waveform) waveform = waveform.splice(0, 60);

  return (
    <Wrapper
      flexWrap="nowrap"
      gap={8}
      justifyContent="space-between"
      style={{width: '100%'}}
    >
      <button className="voice__delete" type="button" onClick={handleClick}>
        <Icons.TrashSimple color="red" size={16} />
        {t`Delete`}
      </button>

      <Wrapper alignItems="center" flexWrap="nowrap" gap={8}>
        {base64 && (
          <AudioPlayer
            duration={field.value?.duration}
            src={base64}
            waveform={waveform}
            isAlt
          />
        )}

        <SaveButton color="transparent">
          <Icons.PaperPlaneRight />
        </SaveButton>
      </Wrapper>
    </Wrapper>
  );
};

export default Preview;
