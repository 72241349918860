import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {SingleSelect} from 'components';
import {useGetInstanceLabel} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const InstanceChatKey = () => {
  const {activeInstances} = useWorkspaceContext();

  const [field, meta, helpers] = useField<string | null>('instance_chat_key');
  const {t} = useTranslation();
  const getInstanceLabel = useGetInstanceLabel();

  const isValid = !meta.error || !meta.touched;

  const options = activeInstances
    .filter(instance => instance.version == 'chat')
    .map(instance => ({
      label: getInstanceLabel(instance),
      value: instance.chat_key
    }));

  return (
    <SingleSelect
      {...field}
      isValid={isValid}
      onChange={helpers.setValue}
      options={options}
      placeholder={t`Connection`}
    />
  );
};

export default InstanceChatKey;
