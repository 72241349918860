import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';

import {DocIcon, Media, ProgressBar, Text, Wrapper} from 'components';
import {getSizeLimit} from 'common/actions';
import {useConvertBytes} from 'common/hooks';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useFooterContext} from '../../../../../footer-context';
import * as AppEnv from 'app-env';

export interface Preview {
  file?: File;
  isValid: boolean;
  src: string;
}

interface PreviewParams {
  attach: AppEnv.Attach;
  index: number;
}

const Preview = ({attach, index}: PreviewParams) => {
  const {instance} = useDispatchContext();
  const {progress} = useFooterContext();

  const convertBytes = useConvertBytes();

  const fileName = attach.file?.name || attach.title;

  const limit = attach.file ? getSizeLimit(instance, attach.file) : undefined;
  const convertedLimit = limit ? convertBytes(limit) : undefined;

  const convertedSize = attach.file
    ? convertBytes(attach.file.size)
    : undefined;

  const isMedia =
    attach.type.includes('image') || attach.type.includes('video');

  const sizeLimit = attach.file ? (
    <Text size={14}>
      <span style={{color: attach.isValid ? '#06c755' : '#ff334b'}}>
        {convertedSize}
      </span>

      {` / ${convertedLimit}`}
    </Text>
  ) : undefined;

  return (
    <>
      {isMedia ? (
        <Wrapper direction="column" gap={8}>
          <div className="preview">
            <Media
              isAutoPlay
              isPreview
              src={attach.src}
              type={attach.type.includes('video') ? 'video' : undefined}
            />

            {typeof progress[index] == 'number' && (
              <div className="preview__progress-bar">
                <CircularProgressbar
                  styles={buildStyles({
                    pathColor: '#fff',
                    pathTransitionDuration: 0.3,
                    trailColor: 'rgb(255, 255, 255, .4)'
                  })}
                  value={progress[index]}
                />
              </div>
            )}
          </div>

          {sizeLimit}
        </Wrapper>
      ) : (
        <Wrapper direction="column" gap={12}>
          <Wrapper
            alignItems="center"
            flexWrap="nowrap"
            gap={12}
            style={{width: '100%'}}
          >
            <DocIcon fileName={fileName} />

            <Wrapper direction="column" gap={2} style={{minWidth: 0}}>
              <Text fontWeight={600} isNoWrap size={16}>
                {fileName}
              </Text>

              {sizeLimit}
            </Wrapper>
          </Wrapper>

          {typeof progress[index] == 'number' && (
            <ProgressBar isAlt percent={progress[index]} width="100%" />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Preview;
