import axios from 'axios';
import * as AppEnv from 'app-env';

const getAmo = () => {
  if (window.location.host.endsWith('ru')) return 'https://waamo.whatcrm.ru';
  return 'https://amocrm.whatcrm.net';
};

const getTelegram = () => {
  if (window.location.host.endsWith('ru')) return 'https://tgamo.whatcrm.ru';
  return 'https://telegram.whatcrm.ru';
};

const getBaseURL = (integration: AppEnv.Integration | undefined) => {
  if (integration?.type == 'AMO') return getAmo();
  else if (integration?.type == 'TELEGRAM') return getTelegram();
};

const useInstance = (integration: AppEnv.Integration | undefined) => {
  const baseURL = getBaseURL(integration);
  if (!baseURL) return null;

  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'X-Amo-Token': 'e93e75f097f9b3317c4343e816069e6a'
    }
  });

  return instance;
};

export default useInstance;
