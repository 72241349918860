import React, {useState} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';

import {getUrlParams} from 'common/actions';
import {useRequestBitrix24, useUploadVoice, useUrlParams} from 'common/hooks';
import {Voice} from 'components';
import * as AppEnv from 'app-env';

import Error from './error/error';

interface Values {
  voice: AppEnv.Voice | undefined;
}

interface Record {
  user: AppEnv.User | null;
}

const initialValues: Values = {voice: undefined};

const Record = () => {
  const [isVoiceRecording, setIsVoiceRecording] = useState(false);

  const {fetchVoiceMessage} = useRequestBitrix24(undefined);
  const {error_text, version = 'whatcrm'} = useUrlParams();
  const uploadVoice = useUploadVoice(version);

  const handleSubmit = async (
    values: Values,
    helpers: FormikHelpers<Values>
  ) => {
    const {key} = getUrlParams();
    if (!key) return true;

    const {voice} = values;
    if (!voice) return true;

    const src = await uploadVoice(voice.blob);
    if (!src) return true;

    await fetchVoiceMessage(key, src);

    helpers.resetForm();
    setIsVoiceRecording(false);

    return true;
  };

  return (
    <div className="voice-page">
      {error_text ? (
        <Error />
      ) : (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <Voice
              isAlt
              isVoiceRecording={isVoiceRecording}
              setIsVoiceRecording={setIsVoiceRecording}
              version={version}
            />
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default Record;
