import React from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {SingleSelect, SelectOption} from 'components';
import {useManagerContext} from '../manager-context';
import {useRequest} from 'common/hooks';
import {useUpdManagerRoles} from '../hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

type Value = '' | 'read' | 'write';

interface Permit {
  label: string;
  roleKey: AppEnv.RoleKey;
}

const Permit = ({label, roleKey}: Permit) => {
  const {user} = useWorkspaceContext();
  const {manager} = useManagerContext();

  const {postManagersUpdate} = useRequest();
  const {t} = useTranslation();
  const updManagerRoles = useUpdManagerRoles();

  const handleChange = async (value: Value) => {
    const {integration} = user;

    const newValue = {read: value != '', write: value == 'write'};
    const newRoles = upd(manager.roles, {[roleKey]: {$set: newValue}});

    updManagerRoles(newRoles);

    const res = await postManagersUpdate(integration.id, manager.id, {
      roles: newRoles
    });

    if (res) return;
    updManagerRoles(manager.roles);
  };

  const {roles} = manager;

  const options: SelectOption<Value>[] = [
    {label: t`No access`, value: ''},
    {label: t`Read-only`, value: 'read'},
    {label: t`Full access`, value: 'write'}
  ];

  const role = roles[roleKey];

  return (
    <SingleSelect<Value>
      isAlt
      maxWidth={160}
      onChange={handleChange}
      optgroup={label}
      options={options}
      value={role.write ? 'write' : role.read ? 'read' : ''}
    />
  );
};

export default Permit;
