import React, {useState} from 'react';
import {formatPhoneNumber} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Button, Confirm, Icons, TableData, TableRow} from 'components';
import {ManagerForm} from '../components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Manager {
  manager: AppEnv.Manager;
  setManagerList: AppEnv.SetState<AppEnv.Manager[] | undefined>;
}

const Manager = ({manager, setManagerList}: Manager) => {
  const {setNotification} = useAppContext();
  const {user} = useWorkspaceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [isUpdActive, setIsUpdActive] = useState(false);

  const {fetchManagersDelete, postManagersUpdate} = useRequest();
  const {t} = useTranslation();

  const deleteManager = async () => {
    const {integration} = user;

    const res = await fetchManagersDelete(integration.id, manager.id);
    if (!res) return true;

    setIsConfirmActive(false);

    setManagerList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == manager.id);
      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {$splice: [[index, 1]]});
    });

    setNotification({title: t`Staff member has been deleted`});
    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return deleteManager();
    setIsConfirmActive(false);
  };

  const handleSubmit = async (values: Omit<AppEnv.Manager, 'id' | 'roles'>) => {
    const {integration} = user;

    const res = await postManagersUpdate(integration.id, manager.id, values);
    if (!res) return true;

    setIsUpdActive(false);

    setManagerList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == manager.id);
      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {[index]: {$set: res}});
    });

    setNotification({title: t`Staff member has been edited`});
    return true;
  };

  const accessInstances = manager.access_instances
    ? JSON.parse(manager.access_instances)
    : [];

  const [firstAccessInstance] = accessInstances;
  const {roles} = user.manager;
  const {managers} = roles;

  return (
    <TableRow isActive={isConfirmActive || isUpdActive}>
      <TableData>{manager.fullname}</TableData>
      <TableData>{manager.email}</TableData>
      <TableData>{formatPhoneNumber(manager.phone)}</TableData>

      <TableData>
        {accessInstances.length
          ? firstAccessInstance && (
              <>
                {formatPhoneNumber(firstAccessInstance)}

                {accessInstances.length > 1 &&
                  ` + ${accessInstances.length - 1}`}
              </>
            )
          : t`All`}
      </TableData>

      {managers.write && (
        <>
          <TableData isIcon>
            <Button color="transparent" onClick={() => setIsUpdActive(true)}>
              <Icons.PencilSimple size={20} />
            </Button>

            <ManagerForm
              isActive={isUpdActive}
              manager={manager}
              onSubmit={handleSubmit}
              setIsActive={setIsUpdActive}
            />
          </TableData>

          <TableData isIcon>
            <Button
              color="transparent"
              onClick={() => setIsConfirmActive(true)}
            >
              <Icons.TrashSimple size={20} />
            </Button>

            <Confirm
              isActive={isConfirmActive}
              onClick={handleClick}
              title={t`Delete this staff member?`}
            />
          </TableData>
        </>
      )}
    </TableRow>
  );
};

export default Manager;
