import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Placeholder, Preloader} from 'components';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import BonusList from './bonus-list/bonus-list';

const Telphin = () => {
  const {user} = useWorkspaceContext();
  const [bonusList, setBonusList] = useState<AppEnv.TelphinBonus[]>();

  const {fetchTelphinServices} = useRequest();
  const {t} = useTranslation();

  const getBonusList = async () => {
    const {integration} = user;
    const res = await fetchTelphinServices(integration.id);
    setBonusList(res?.bonuses || []);
  };

  useEffect(() => {
    getBonusList();
  }, []);

  return (
    <>
      {bonusList ? (
        bonusList.length ? (
          <BonusList bonusList={bonusList} />
        ) : (
          <Placeholder isMaxHeight title={t`No services`} />
        )
      ) : (
        <Preloader isMaxHeight />
      )}
    </>
  );
};

export default Telphin;
