import React, {forwardRef, useEffect} from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {useField, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';

import {useChatContext} from '../../../chat-context';
import {useFooterContext} from '../../footer-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import useHandleFiles from '../use-handle-files';

const Text = forwardRef<HTMLTextAreaElement>((props, ref) => {
  const {user} = useWorkspaceContext();
  const {dialog, quotedMsg} = useChatContext();

  const {isAttachsFormActive, setTemplateState, templateState} =
    useFooterContext();

  const [field, , helpers] = useField<string>('text');
  const {isSubmitting, submitForm} = useFormikContext();
  const {t} = useTranslation();
  const handleFiles = useHandleFiles();

  useEffect(() => {
    if (!isAttachsFormActive) focusText();
  }, [isAttachsFormActive]);

  useEffect(() => {
    if (!isSubmitting) focusText();
  }, [isSubmitting]);

  useEffect(() => {
    if (!templateState.isActive) focusText();
  }, [templateState.isActive]);

  useEffect(() => {
    focusText();
  }, [quotedMsg]);

  useEffect(() => {
    if (dialog.version == 'telegram' && dialog.draftMessage)
      helpers.setValue(dialog.draftMessage.inputMessageText.text.text);
  }, []);

  const focusText = () => {
    if (isMobile || !ref || !('current' in ref) || !ref.current) return;

    ref.current.focus();
    ref.current.selectionStart = ref.current.value.length;
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = e => {
    if (
      (e.code == 'Enter' || e.code == 'NumpadEnter') &&
      !e.shiftKey &&
      !isSubmitting
    ) {
      submitForm();
      e.preventDefault();
    } else if (e.code == 'Slash' && !field.value) {
      setTemplateState({isActive: true, isFocus: true});
      e.preventDefault();
    }
  };

  const handlePaste: React.ClipboardEventHandler<HTMLTextAreaElement> = e => {
    const files = Object.values(e.clipboardData.files);
    if (!files.length) return;

    handleFiles(files);
    e.preventDefault();
  };

  const {manager} = user;
  const {messages} = manager.roles;

  return (
    <ReactTextareaAutosize
      className="chat__input"
      disabled={!messages.write}
      onChange={e => helpers.setValue(e.target.value)}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      placeholder={messages.write ? `${t`Message`}...` : undefined}
      ref={ref}
      value={isAttachsFormActive ? '' : field.value}
    />
  );
});

Text.displayName = 'Text';
export default Text;
